import React, { CSSProperties } from 'react';
import { userListProfile } from '../../../_mockApis/user-profile/types';
import { Box, Chip, Grid, IconButton, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import MoreTwoToneIcon from '@mui/icons-material/MoreTwoTone';
import { Theme, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { CategoryListType, ContentListType } from '../../../types/content.types';
import { Draggable } from 'react-beautiful-dnd';
import { dayFormatTime } from '../../../utils/times/timeFormats';

interface props {
    row: ContentListType;
    index: number;
}

const UserContentRow = ({ row, index }: props) => {
    const theme = useTheme();
    const navigation = useNavigate();
    const handlerGoToProfile = (idx: number) => {
        navigation(`/content/music/add/${idx}`);
    };

    const TransId = (dat: userListProfile) => {
        console.log(dat.snsType);
        switch (dat.snsType) {
            case 1:
                return dat.userId;
            case 2:
                return 'Google';
            case 3:
                return 'Apple';
            case 4:
                return 'Paypal';
            default:
                return dat.userId;
        }
    };
    const [open, setOpen] = React.useState(false);
    return (
        <TableRow>
            <TableCell>
                <Typography align="left" variant="subtitle2" noWrap>
                    <Box
                        alignItems={'center'}
                        justifyContent={'center'}
                        border={1}
                        borderColor={'#222'}
                        borderRadius={'12px'}
                        width={`${125}px`}
                        height={`${125}px`}
                        margin={'8px'}
                    >
                        <img src={row.image} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt={row.image} />
                    </Box>
                </Typography>
            </TableCell>
            <TableCell>
                <Grid container spacing={2} alignItems="center" style={{ backgroundColor: 'transparent' }}>
                    <Grid item xs zeroMinWidth style={{ backgroundColor: 'transparent' }}>
                        <Typography align="left" variant="subtitle1" component="div">
                            {row.title}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Typography align="left" variant="subtitle2" noWrap>
                    {dayFormatTime(row?.bookMark?.createdAt)}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography align="left" variant="subtitle2" noWrap>
                    {row?.bookMark?.brainV}
                </Typography>
            </TableCell>
            <TableCell align="center" sx={{ pr: 3 }}>
                <Stack direction="row" justifyContent="center" alignItems="center">
                    {/* <Tooltip placement="top" title="Message"> */}
                    {/*    <IconButton color="primary" aria-label="delete" size="large"> */}
                    {/*        <ChatBubbleTwoToneIcon sx={{ fontSize: '1.1rem' }} /> */}
                    {/*    </IconButton> */}
                    {/* </Tooltip> */}
                    <Tooltip placement="top" title="More">
                        <IconButton
                            onClick={() => handlerGoToProfile(row.idx)}
                            color="primary"
                            sx={{
                                color: theme.palette.orange.dark,
                                borderColor: theme.palette.orange.main,
                                '&:hover ': { background: theme.palette.orange.light }
                            }}
                            size="large"
                        >
                            <MoreTwoToneIcon sx={{ fontSize: '1.1rem' }} />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </TableCell>
        </TableRow>
    );
};

export default UserContentRow;
