import React, { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Button,
    Checkbox,
    Chip,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Pagination,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';

// project imports
import Avatar from 'ui-component/extended/Avatar';
import axios from 'utils/axios';
import { userListProfile, UserProfile } from '_mockApis/user-profile/types';

// assets
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import MoreTwoToneIcon from '@mui/icons-material/MoreTwoTone';
import useAxios from '../../hooks/useAxios';

import FaceIcon from '@mui/icons-material/Face';
import { gridSpacing } from '../../store/constant';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import UserDownload from '../../components/csv/user.download';
import Select from '@mui/material/Select';
import { ContentListType } from '../../types/content.types';
import { useNavigate, useParams } from 'react-router-dom';
import ContentCategoryRow from './content.category.row';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { LOADING_END, LOADING_START } from '../../store/actions';
import { useDispatch } from 'react-redux';
// ==============================|| USER LIST 1 ||============================== //
interface IParamsCategoryType {
    idx: string;
}
const ContentCategoryList = () => {
    const theme = useTheme();
    const { idx } = useParams<keyof IParamsCategoryType>() as IParamsCategoryType;
    console.log('category', idx);
    const { axiosService } = useAxios();
    const [data, setData] = React.useState<ContentListType[]>([]);
    const [recommend, setRecommend] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [listParam, setListParam] = useState<{
        skip: number;
        take: number;
        nameSort: 'ASC' | 'DESC';
        creditSort: 'ASC' | 'DESC';
    }>({
        skip: 0,
        take: 10,
        nameSort: 'ASC',
        creditSort: 'ASC'
    });
    const [paging, setPaging] = useState({
        allPage: 0,
        count: 0
    });
    const getData = async () => {
        const response = await axiosService.post('/content/music/category', { idx });
        setData(response.data.list);
        setPaging((p) => ({
            ...p,
            allPage: response.data.allPage,
            count: response.data.count
        }));
    };

    React.useEffect(() => {
        getData();
    }, [idx]);

    const pageChange = (event, page) => {
        setListParam((p) => ({ ...p, skip: page - 1 }));
    };

    const typeHandleChange = (type) => {
        const value = type.target.value;
        setListParam((p) => ({ ...p, type: value }));
    };
    const navigation = useNavigate();
    const dispatch = useDispatch();

    // Drag on Drop
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };
    const onDragEnd = (result: DropResult) => {
        const { source, destination, draggableId, type } = result;
        console.log('result', result);

        if (!destination) return;

        if (destination.droppableId === source.droppableId && destination.index === source.index) return;

        if (!result.destination) {
            return;
        }

        const items = reorder(data, result.source.index, result.destination.index);

        // @ts-ignore
        setData([...items]);
        const orderData = items.map((v: any, index) => ({ order: index + 1, idx: v.idx as any, name: v.title }));
        console.log('ORDER', orderData);
        // const newUserStoryOrder = Array.from(mainList);
        // newUserStoryOrder.splice(source.index, 1); // remove dragged column
        // newUserStoryOrder.splice(destination?.index, 0, draggableId); // set column new position
        // setMainList([...newUserStoryOrder]);
    };

    const sortListSave = async () => {
        const orderData = data.map((v: any, index) => ({ order: index + 1, idx: v.idx as any, name: v.title }));
        // setData([...datav]);
        try {
            dispatch({ type: LOADING_START });
            await axiosService.post('content/content/order/update', { data: orderData });
            await getData();
        } catch (e) {
        } finally {
            dispatch({ type: LOADING_END });
        }
        // console.log('SORT, ', sort);
        // const sortData = sort.map((idx: string, index: number) => pickData.filter((item) => `category_${item.idx}` === idx)[0]);
        // setData([...sortData]);
        // console.log('SORT DATA', sortData);
    };
    ///
    return (
        <>
            <Grid container flexDirection={'row-reverse'}>
                <Grid item>
                    <Button onClick={() => navigation(`/content/music/add/new?ct=${idx}`)}> Add Content</Button>
                </Grid>
            </Grid>
            <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                <Button variant={'outlined'} onClick={() => sortListSave()}>
                    SAVE
                </Button>
            </Grid>
            <TableContainer>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <Table {...provided.droppableProps} ref={provided.innerRef}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Title</TableCell>
                                        <TableCell>Image</TableCell>
                                        <TableCell>Category</TableCell>
                                        <TableCell>rank</TableCell>
                                        <TableCell>duration</TableCell>
                                        <TableCell>status</TableCell>
                                        <TableCell>Recommend</TableCell>
                                        <TableCell align="center" sx={{ pr: 3 }}>
                                            Actions
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>{data && data.map((row, index) => <ContentCategoryRow row={row} index={index} />)}</TableBody>
                                {provided.placeholder}
                            </Table>
                        )}
                    </Droppable>
                </DragDropContext>
            </TableContainer>
        </>
    );
};

export default ContentCategoryList;
