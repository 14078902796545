import { IconQrcode, IconSettings, IconPin, IconMail } from '@tabler/icons';
import { FormattedMessage } from 'react-intl';

const icons = {
    IconQrcode,
    IconSettings,
    IconPin,
    IconMail
};

const settingRoute = {
    id: 'setting-route',
    type: 'group',
    children: [
        {
            id: 'setting-push',
            title: 'Messaging',
            type: 'item',
            icon: icons.IconMail,
            url: '/setting/messaging'
        }
    ]
};

export default settingRoute;
