import React, { useEffect, useState } from 'react';
import { pickListTypes } from '../../types/pick';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, MenuItem, Typography } from '@mui/material';
import { orderList } from '../../types/order.type';
import { OrderStatusText } from './order.list';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import useAxiosServices from '../../hooks/useAxios';
import { toast } from 'react-toastify';
import { addressFormat, ToastSetting } from '../../utils/common';
import AlertDialog from '../../ui-component/dialog/AlertDialog';

interface Props {
    row: orderList;
    profileView: boolean;
}

const ch = [
    { label: 'Wait', value: '1' },
    { label: 'Delivered', value: '2' },
    { label: 'Completed', value: '3' },
    { label: 'Cancellation', value: '5' },
    { label: 'Refund request', value: '6' }
];
const OrderMore = ({ row, profileView }: Props) => {
    const theme = useTheme();
    const navigation = useNavigate();
    const [statusRow, setStatusRow] = useState(4);
    useEffect(() => {
        setStatusRow(row.status);
    }, [row]);
    const handlerGoToProfile = (idx: number) => {
        navigation(`/user/default/${idx}`);
    };

    const [changeStatus, setChangeStatus] = useState(String(row.status));
    const handleChange = async (event: SelectChangeEvent) => {
        console.log(event?.target.value);
        // @ts-ignore
        event?.target.value && setChangeStatus(event.target.value);
        await changeHanlder(event.target.value);
    };
    const { axiosService } = useAxiosServices();
    const changeHanlder = async (type) => {
        const api =
            type === 6
                ? await axiosService.post('order/cancel/user/admin', { orderCode: row.orderCode, status: type })
                : await axiosService.post('order/status/change', { orderCode: row.orderCode, status: type });
        const { data, status } = api.data;
        if (status) {
            toast.info('Success Status Change', { ...ToastSetting.default });
            setStatusRow(type);
        } else {
            toast.error('error', { ...ToastSetting.default });
        }
        setRefundOpen(false);
    };
    const [refundOpen, setRefundOpen] = useState(false);
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid item xs>
                        <Grid container alignItems={'center'} marginY={2}>
                            <Grid item mr={2}>
                                <Typography align="left" variant="subtitle2" component="div">
                                    Status
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography align="left" variant="subtitle1" noWrap>
                                    {OrderStatusText(row.status)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container alignItems={'center'} marginY={2}>
                            <Grid item mr={2}>
                                <Typography align="left" variant="subtitle2" component="div">
                                    Address
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography align="left" variant="subtitle1" component="div">
                                    {row.address}
                                    {'\n'}
                                    {addressFormat(row.zipCode, row.addressMore)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Grid container alignItems={'center'} marginY={2}>
                            <Grid item mr={2}>
                                <Typography align="left" variant="subtitle2" component="div">
                                    Comment
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography align="left" variant="subtitle1" noWrap>
                                    {''}
                                </Typography>
                            </Grid>
                        </Grid>
                        {(statusRow === 1 || statusRow === 2 || statusRow === 3) && (
                            <Grid container alignItems={'center'} xs={12}>
                                <Grid item mr={2} xs={6}>
                                    <Typography align="left" variant="subtitle2" component="div">
                                        Status Change
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Grid item ml={2}>
                                        <Select
                                            id="columnId"
                                            name="columnId"
                                            value={String(changeStatus)}
                                            onChange={handleChange}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            {ch.map(({ value: monthValue, label }, index: number) => (
                                                <MenuItem key={index} value={monthValue}>
                                                    {label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {(statusRow === 1 || statusRow === 2 || statusRow === 3 || statusRow === 6) && (
                            <Grid container alignItems={'center'} marginY={2}>
                                <Grid item mr={2}>
                                    <Button variant={'contained'} onClick={() => setRefundOpen(true)}>
                                        <Typography align="left" variant="subtitle2" component="div" color={'#fff'}>
                                            Refund
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <AlertDialog
                title={'Refund'}
                content={`Do you want to Refund this order?`}
                open={refundOpen}
                handleClose={setRefundOpen}
                handleSuccess={() => changeHanlder(4)}
            />
        </>
    );
};

export default OrderMore;
