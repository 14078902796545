import React, { ReactNode, Ref } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';

export interface SubCardProps {
    children: ReactNode | string | null;
    content?: boolean;
    className?: string;
    contentClass?: string;
    darkTitle?: boolean;
    secondary?: ReactNode | string | {};
    sx?: {};
    contentSX?: {};
    title?: ReactNode | string | {};
    press?: any;
    pressTitle?: string;
}

// ==============================|| CUSTOM SUB CARD ||============================== //

const SubCard = React.forwardRef(
    (
        {
            pressTitle,
            press,
            children,
            className,
            content,
            contentClass,
            darkTitle,
            secondary,
            sx = {},
            contentSX = {},
            title,
            ...others
        }: SubCardProps,
        ref: Ref<HTMLDivElement>
    ) => {
        const theme = useTheme();

        return (
            <Card
                ref={ref}
                sx={{
                    border: '1px solid',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 15 : theme.palette.primary.light,
                    ':hover': {
                        boxShadow: theme.palette.mode === 'dark' ? '0 2px 14px 0 rgb(33 150 243 / 10%)' : '0 2px 14px 0 rgb(32 40 45 / 8%)'
                    },
                    ...sx
                }}
                {...others}
                style={{ background: 'transparent' }}
            >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    {!darkTitle && title && (
                        <CardHeader sx={{ p: 2.5 }} title={<Typography variant="h5">{title}</Typography>} action={secondary} />
                    )}
                    {press && pressTitle && title && (
                        <CardHeader
                            style={{ cursor: 'pointer' }}
                            onClick={() => press()}
                            sx={{ p: 2.5 }}
                            title={
                                <Typography variant="h5" color={theme.palette.primary.dark}>
                                    {pressTitle}
                                </Typography>
                            }
                            action={secondary}
                        />
                    )}
                </div>
                {/* card header and action */}

                {darkTitle && title && (
                    <CardHeader sx={{ p: 2.5 }} title={<Typography variant="h4">{title}</Typography>} action={secondary} />
                )}

                {/* content & header divider */}
                {title && (
                    <Divider
                        sx={{
                            opacity: 1,
                            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 15 : theme.palette.primary.light
                        }}
                    />
                )}

                {/* card content */}
                {content && (
                    <CardContent sx={{ p: 2.5, ...contentSX }} className={contentClass || ''}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

SubCard.defaultProps = {
    content: true
};

export default SubCard;
