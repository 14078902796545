import React, { useState, CSSProperties } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material-ui
import { Theme, useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    ButtonBase,
    Chip,
    Collapse,
    Grid,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';

// third-party

// project imports
import { SNACKBAR_OPEN, DELETE_STORY } from 'store/actions';

// assets
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';

import { useNavigate } from 'react-router-dom';
import { TransactionListTypes } from '../../types/charity';
import { TransactionTypeCase } from '../../utils/common';
import { locationListType } from '../../types/pick';
import { format } from 'date-fns';

interface Props {
    value: locationListType;
    index: number;
}

const SuburbRow = ({ value, index }: Props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigation = useNavigate();

    return (
        <>
            <TableRow hover>
                <TableCell sx={{ pl: 3, minWidth: 40, width: 40, height: 46 }}>{value.idx}</TableCell>
                <TableCell component="th" scope="row">
                    <Typography align="left" variant="subtitle2" noWrap>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs zeroMinWidth>
                                <Typography align="left" variant="subtitle1" component="div">
                                    {value.title}{' '}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Typography>
                </TableCell>

                <TableCell>
                    <Typography align="left" variant="subtitle2" noWrap>
                        {value.active === 1 ? (
                            <Chip
                                label="Active"
                                size="small"
                                sx={{
                                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                                    color: theme.palette.success.dark
                                }}
                            />
                        ) : (
                            <Chip
                                label="Non"
                                size="small"
                                sx={{
                                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
                                    color: theme.palette.warning.dark
                                }}
                            />
                        )}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography align="left" variant="subtitle2" noWrap>
                        {format(new Date(value.createdAt), 'yyyy-MM-dd')}
                    </Typography>
                </TableCell>

                <TableCell sx={{ width: 100, minWidth: 60 }}>
                    <ButtonBase
                        className="more-button"
                        sx={{ borderRadius: '12px' }}
                        onClick={handleClick}
                        aria-controls="menu-comment"
                        aria-haspopup="true"
                    >
                        <IconButton component="span" size="small" disableRipple>
                            <MoreVertTwoToneIcon fontSize="inherit" />
                        </IconButton>
                    </ButtonBase>
                    <Menu
                        id="menu-comment"
                        // @ts-ignore
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        variant="selectedMenu"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                navigate(`/setting/suburb/${value.idx}`);
                            }}
                        >
                            More
                        </MenuItem>
                    </Menu>
                </TableCell>
            </TableRow>
        </>
    );
};

export default SuburbRow;
