import React, { useState, CSSProperties } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material-ui
import { Theme, useTheme } from '@mui/material/styles';
import {
    Box,
    ButtonBase,
    Chip,
    Collapse,
    Grid,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';

// third-party

// project imports
import { SNACKBAR_OPEN, DELETE_STORY } from 'store/actions';

// assets
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';

// types
import { collectionList, pickListTypes } from 'types/pick';
import Avatar from '../../ui-component/extended/Avatar';
import FaceIcon from '@mui/icons-material/Face';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import dayFormat, { timeFind } from '../../utils/times/timeFormats';
import PickTypeCard from '../../ui-component/swith/pickTypeCard';
import { useNavigate } from 'react-router-dom';
import { orderList } from '../../types/order.type';
import { OrderStatusText } from './order.list';
import OrderMore from './order.more';
import OrderInner from './order.inner';

interface Props {
    value: orderList;
    index: number;
}

const OrderRow = ({ value, index }: Props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePreventOpen = (e) => {
        e.stopPropagation();
        setOpen((p) => !p);
    };
    const handlePreventGotoUser = (e, idx) => {
        e.stopPropagation();
        navigate(`/user/default/${idx}`);
    };
    console.log(value);
    return (
        <>
            <TableRow hover style={{ cursor: 'pointer' }} onClick={(e) => handlePreventOpen(e)}>
                <TableCell sx={{ pl: 3, minWidth: 40, width: 40, height: 46 }}>
                    <Typography align="left" variant="subtitle2" noWrap>
                        {value.idx}
                    </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                    <Typography align="left" variant="subtitle2" noWrap>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs zeroMinWidth>
                                <Typography align="left" variant="subtitle1" component="div">
                                    ORDER{value.orderCode}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs zeroMinWidth onClick={(e) => handlePreventGotoUser(e, value.user?.idx)}>
                            <Typography align="left" variant="subtitle1" component="div">
                                {value.user?.userName} {value.user?.userFirstName}
                            </Typography>

                            <Typography align="left" variant="subtitle2" noWrap>
                                {value.user?.userEmail}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Typography align="left" variant="subtitle2" noWrap>
                        ${value.totalAmount}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography align="left" variant="subtitle2" noWrap>
                        {value.payType === 1 ? 'credit' : 'paypal'}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography align="left" variant="subtitle2" noWrap>
                        {OrderStatusText(value.status)}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography align="left" variant="subtitle2" noWrap>
                        {value.createdAt ? dayFormat(new Date(value.createdAt)) : dayFormat(new Date())}
                    </Typography>
                </TableCell>
                <TableCell sx={{ width: 30, minWidth: 60 }}>
                    <Menu
                        id="menu-comment"
                        // @ts-ignore
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        variant="selectedMenu"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                navigate(`/routing/detail/${value.idx}`);
                            }}
                        >
                            More
                        </MenuItem>
                    </Menu>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box
                            sx={{
                                margin: 0
                            }}
                        >
                            {value?.orders && <OrderInner value={value} index={0} />}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default OrderRow;
