import { IconClipboardList } from '@tabler/icons';

const icons = {
    IconClipboardList
};

const contentRoute = {
    id: 'content-route',
    type: 'group',
    children: [
        {
            id: 'Content',
            title: 'Category',
            type: 'item',
            icon: icons.IconClipboardList,
            url: '/content/category/list'
        }
    ]
};

export default contentRoute;
