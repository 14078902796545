import React, { useState } from 'react';
import { driverListProfile, userListProfile } from '../../_mockApis/user-profile/types';
import { Chip, Grid, IconButton, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import Avatar from '../../ui-component/extended/Avatar';
import FaceIcon from '@mui/icons-material/Face';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import MoreTwoToneIcon from '@mui/icons-material/MoreTwoTone';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

interface props {
    row: driverListProfile;
    index: number;
}

const DriverRows = ({ row, index }: props) => {
    const theme = useTheme();
    const navigation = useNavigate();
    const handlerGoToProfile = (idx: number) => {
        navigation(`/user/default/${idx}`);
    };
    return (
        <TableRow hover key={index}>
            <TableCell sx={{ pl: 3 }}>{row.userId}</TableCell>
            <TableCell>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs zeroMinWidth>
                        <Typography align="left" variant="subtitle1" component="div">
                            {row.userFirstName} {row.userName}
                            {row.userStatus === 1 && <CheckCircleIcon sx={{ color: 'success.dark', width: 14, height: 14 }} />}
                        </Typography>
                        <Typography align="left" variant="subtitle2" noWrap>
                            {row.userNickName}
                        </Typography>
                        <Typography align="left" variant="subtitle2" noWrap>
                            {row.userEmail}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Typography align="left" variant="subtitle2" noWrap>
                    {row.userAddress}
                </Typography>
            </TableCell>
            <TableCell>{row.userPick}</TableCell>
            <TableCell>
                {row.userWait === 1 && (
                    <Chip
                        label="Active"
                        size="small"
                        sx={{
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                            color: theme.palette.success.dark
                        }}
                    />
                )}
                {row.userWait === 2 && (
                    <Chip
                        label="Wait"
                        size="small"
                        sx={{
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light + 80,
                            color: theme.palette.orange.dark
                        }}
                    />
                )}
            </TableCell>
            <TableCell>
                {row.driver.driverStatus === 1 && (
                    <Chip
                        label="Rest"
                        size="small"
                        sx={{
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                            color: theme.palette.success.dark
                        }}
                    />
                )}
                {row.driver.driverStatus === 2 && (
                    <Chip
                        label="Driving"
                        size="small"
                        sx={{
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light + 80,
                            color: theme.palette.orange.dark
                        }}
                    />
                )}
                {row.driver.driverStatus === 3 && (
                    <Chip
                        label="Pending"
                        size="small"
                        sx={{
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
                            color: theme.palette.warning.dark
                        }}
                    />
                )}
            </TableCell>
            <TableCell align="center" sx={{ pr: 3 }}>
                <Stack direction="row" justifyContent="center" alignItems="center">
                    <Tooltip placement="top" title="More">
                        <IconButton
                            onClick={() => handlerGoToProfile(row.idx)}
                            color="primary"
                            sx={{
                                color: theme.palette.orange.dark,
                                borderColor: theme.palette.orange.main,
                                '&:hover ': { background: theme.palette.orange.light }
                            }}
                            size="large"
                        >
                            <MoreTwoToneIcon sx={{ fontSize: '1.1rem' }} />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </TableCell>
        </TableRow>
    );
};

export default DriverRows;
