import React, { useState } from 'react';
import { useTheme } from '@mui/styles';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    InputAdornment,
    Menu,
    MenuItem,
    OutlinedInput,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { IconSearch } from '@tabler/icons';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import MainCard from '../../ui-component/cards/MainCard';
import useAxios from '../../hooks/useAxios';
import { driverListProfile } from '../../_mockApis/user-profile/types';

import { ChangeEventFunc } from '../../types';
import { useNavigate } from 'react-router-dom';
import { locationListType, qrListType } from '../../types/pick';
import SuburbRow from './suburb.row';
import QrRow from './qr.row';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';
import AlertDialog from '../../ui-component/dialog/AlertDialog';

const QrList = () => {
    const theme = useTheme();
    const { axiosService } = useAxios();
    const [data, setData] = React.useState<qrListType[]>([]);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [tempId, setTempId] = useState('');
    const [listParam, setListParam] = useState<{
        skip: number;
        take: number;
        nameSort: 'ASC' | 'DESC';
        creditSort: 'ASC' | 'DESC';
        status: number;
    }>({
        skip: 0,
        take: 10,
        nameSort: 'ASC',
        creditSort: 'ASC',
        status: 0
    });
    const [paging, setPaging] = useState({
        allPage: 0,
        count: 0
    });

    const openAlert = (idx) => {
        setDeleteOpen(true);
        setTempId(idx);
    };

    const deleteApi = async () => {
        const api = await axiosService.post('/pick/qr/delete', { idx: tempId });
        const { status } = api.data;
        if (status) {
            toast.info('Delete row', { ...ToastSetting.default });
            await getData();
        } else {
            toast.info('Delete error', { ...ToastSetting.default });
        }
        setDeleteOpen(false);
    };

    const getData = async () => {
        const response = await axiosService.post('/pick/qr/list', { wait: 0, ...listParam });
        setData(response.data.list);
        setPaging((p) => ({
            ...p,
            allPage: response.data.allPage,
            count: response.data.count
        }));
    };

    React.useEffect(() => {
        getData();
    }, [listParam]);
    const pageChange = (event, page) => {
        setListParam((p) => ({ ...p, skip: page - 1 }));
    };

    const [anchorEl, setAnchorEl] = React.useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigation = useNavigate();
    return (
        <>
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Typography variant="h3">QrCode List</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Grid item>
                                    <Button onClick={() => navigation('/setting/qrCode')}>Created</Button>
                                </Grid>
                                <OutlinedInput
                                    id="input-search"
                                    placeholder="Search"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <IconSearch stroke={0.5} size="0.5rem" />
                                        </InputAdornment>
                                    }
                                    size="small"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                }
                content={false}
            >
                <>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ pl: 3 }}>#</TableCell>
                                    <TableCell>Qr Number</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Created</TableCell>
                                    <TableCell align="center" sx={{ pr: 3 }}>
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((v, i) => (
                                    <QrRow value={v} index={i} openAlert={openAlert} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid item xs={12} sx={{ p: 3 }}>
                        <Grid container justifyContent="space-between" spacing={gridSpacing}>
                            <Grid item>
                                <Pagination page={listParam.skip + 1} count={paging.allPage} color="secondary" onChange={pageChange} />
                            </Grid>
                            <Grid item>
                                <Button
                                    size="large"
                                    sx={{ color: theme.palette.grey[900] }}
                                    color="secondary"
                                    endIcon={<ExpandMoreRoundedIcon />}
                                    onClick={handleClick}
                                >
                                    10 Rows
                                </Button>
                                <Menu
                                    id="menu-user-list"
                                    // @ts-ignore
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    variant="selectedMenu"
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                >
                                    <MenuItem onClick={handleClose}> 10+</MenuItem>
                                    <MenuItem onClick={handleClose}> 20+</MenuItem>
                                    <MenuItem onClick={handleClose}> 30+</MenuItem>
                                </Menu>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            </MainCard>
            <AlertDialog
                title={'Delete rows'}
                content={`Do you want to Delete row?`}
                open={deleteOpen}
                handleClose={setDeleteOpen}
                handleSuccess={() => deleteApi()}
            />
        </>
    );
};

export default QrList;
