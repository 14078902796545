import React, { useEffect, useMemo, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    MenuItem,
    Stack,
    Tab,
    Tabs,
    TextareaAutosize,
    TextField,
    Typography
} from '@mui/material';
import { gridSpacing } from '../../store/constant';
import MainCard from '../../ui-component/cards/MainCard';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { userListProfile } from '../../_mockApis/user-profile/types';
import MaskedInput from 'react-text-mask';
import AnimateButton from '../../ui-component/extended/AnimateButton';
import MapDialog from '../../ui-component/dialog/MapDialog';
import { CheckBox, Search } from '@mui/icons-material';
import useAxiosServices from '../../hooks/useAxios';
import AlertDialog from '../../ui-component/dialog/AlertDialog';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';
import { Link, useNavigate, useParams, useRoutes, useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import ReactDraftWysiwyg from '../../ui-component/editor/ReactDraftWysiwyg';
import ImageUploader from '../../ui-component/uploader/imageUploader';
import { FileUpload, useFileUpload } from 'use-file-upload';
import ReactAudioPlayer from 'react-audio-player';
import { CategoryListType } from '../../types/content.types';
import Select from '@mui/material/Select';
import { S3 } from 'aws-sdk';

const validationSchema = yup.object({
    title: yup.string().min(2, 'title should be of minimum 2 characters length').required('title is required'),
    subTitle: yup.string().min(2, 'sub title should be of minimum 2 characters length').required('sub title is required'),
    lank: yup.number().typeError('rank must be a number').positive('rank must be greater than zero').required('rank is required'),
    recommendRank: yup.number().typeError('Recommend rank must be a number').positive('Recommend rank must be greater than zero')
});
function TabPanel(props: { children: React.ReactElement; value: number; index: number }) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`user-tabpanel-${index}`} aria-labelledby={`user-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `user-tab-${index}`,
        'aria-controls': `user-tabpanel-${index}`
    };
}
const MusicAdd = () => {
    const theme = useTheme();
    const { axiosService } = useAxiosServices();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    console.log('searchParams', searchParams.get('ct'));
    const [content, setContent] = useState('');
    const [contentKo, setContentKo] = useState('');
    const [contentHans, setContentHans] = useState('');
    const [contentHant, setContentHant] = useState('');
    const [contentJa, setContentJa] = useState('');

    const [contentKoInit, setContentKoInit] = useState('');
    const [contentHansInit, setContentHansInit] = useState('');
    const [contentHantInit, setContentHantInit] = useState('');
    const [contentJaInit, setContentJaInit] = useState('');

    const [duration, setDuration] = useState(0);
    const [mainImage, setMainImage] = useState(null);
    const [mainUrl, setMainUrl] = useState(null);
    const [brainUrl, setBrainUrl] = useState(null);
    const [use, setUse] = useState(true);
    const [recommend, setRecommend] = useState(true);
    const [progress, setProgress] = useState(0);

    const formik = useFormik({
        initialValues: {
            title: '',
            subTitle: '',
            lank: '',
            recommendRank: 0
        },
        validationSchema,
        onSubmit: async (values) => {
            setAlertOpen(true);
        }
    });

    const [open, setOpen] = useState(false);
    const handlerAddress = (add, lat, lot) => {
        formik.setFieldValue('userAddress', add);
        setOpen((p) => !p);
    };

    useEffect(() => {}, []);

    const [alertOpen, setAlertOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [loadingOpen, setLoadingOpen] = useState(false);

    const [logoImage, setLogoImage] = useState(null);

    const imageHandler = (file: any) => {
        setMainImage(file[0]);
    };
    const imageHandler2 = (file: any) => {
        setLogoImage(file[0]);
    };
    const onCancel = () => {
        setMainImage(null);
    };
    const onCancel2 = () => {
        setLogoImage(null);
    };

    const insertFetch = async () => {
        setAlertOpen(false);
        await musicUpload();
        const { title, subTitle, lank } = formik.values;
        console.log(formik.values);
        // if (!lank) {
        //     return alert('Please input rank or rank must be greater than 0');
        // }
        // if (!modify) {
        //     if (!mainImage) {
        //         return alert('Please add category Image');
        //     }
        // }
        //
        // try {
        //     const form = new FormData();
        //     form.append('title', title);
        //     form.append('subTitle', subTitle);
        //     form.append('lank', lank);
        //     form.append('mainImage', mainImage);
        //     form.append('use', use ? `1` : `0`);
        //     if (modify) {
        //         form.append('idx', idx);
        //     }
        //     const api = await axiosService.post(modify ? 'content/category/update' : 'content/category/add', form, {
        //         headers: { 'Content-type': 'multipart/form-data' }
        //     });
        //
        //     const { status } = api;
        //     if (status) {
        //         toast.info('Success Create Category', { ...ToastSetting.default });
        //         if (modify) {
        //             getData();
        //         } else {
        //             navigate('/content/category/list');
        //         }
        //     } else {
        //         toast.info('Error', { ...ToastSetting.default });
        //     }
        //     return;
        // } catch (e) {
        //     toast.info('Error', { ...ToastSetting.default });
        // } finally {
        //     setAlertOpen(false);
        // }
    };

    const { idx } = useParams();

    useEffect(() => {
        if (idx !== 'new') {
            getData();
        }
        getDataCategory();
    }, [idx]);

    const getData = async () => {
        const api = await axiosService.post('/content/music/detail', { idx });
        const { result, data } = api.data;
        if (result) {
            await formik.setFieldValue('title', data.title);
            await formik.setFieldValue('subTitle', data.subTitle);
            await formik.setFieldValue('lank', data.rank);
            await formik.setFieldValue('recommendRank', data.recommendRank);
            setDuration(data.duration);
            setUse(data.agree === 1);

            setRecommend(data.recommend === 1);

            setImageURL(data.image);
            setMainUrl(data.mainUrl);
            setBrainUrl(data.brainUrl);

            setContent(data.content);
            setContentHans(data.contentHans);
            setContentJa(data.contentJapan);
            setContentHant(data.contentHant);
            setContentKo(data.contentKo);

            setCategoryIdx(data.categoryIdx);
            setInit(data.content);
            setContentHansInit(data.contentHans);
            setContentJaInit(data.contentJapan);
            setContentHantInit(data.contentHant);
            setContentKoInit(data.contentKo);
            setModify(true);
        }
    };

    const musicUpload = async () => {
        const { title, subTitle, lank, recommendRank } = formik.values;
        if (!lank) {
            return alert('Please input rank or rank must be greater than 0');
        }
        if (!modify) {
            if (!mainImage) {
                return alert('Please add content Image');
            }
        }
        if (!modify) {
            if (!files || !file2) {
                return alert('Please add content music');
            }
        }

        if (content === '' || !content) {
            return alert('Please add content');
        }
        if (categoryIdx === 0) {
            return alert('Please add category');
        }

        try {
            setAlertOpen(false);
            setLoadingOpen(true);
            const form = new FormData();
            form.append('title', title);
            form.append('subTitle', subTitle);
            form.append('rank', lank);
            form.append('mainImage', mainImage);
            form.append('use', use ? `1` : `0`);
            form.append('recommend', recommend ? `1` : `0`);
            form.append('duration', `${duration}`);
            files && form.append('mainFile', fileSourceBolb(files));
            file2 && form.append('brainFile', fileSourceBolb(file2));
            form.append('agree', use ? `1` : `0`);
            form.append('content', content);
            form.append('contentJa', contentJa);
            form.append('contentHant', contentHant);
            form.append('contentHans', contentHans);
            form.append('contentKo', contentKo);
            form.append('categoryIdx', `${categoryIdx}`);
            form.append('recommendRank', `${recommendRank}`);
            if (modify) {
                form.append('idx', idx);
            }
            const api = await axiosService.post(modify ? 'content/music/update' : '/content/music/add', form, {
                onUploadProgress: (evt) => {
                    console.log('EVENT', evt);
                    setProgress(Math.round((evt.loaded / evt.total) * 100));
                },

                headers: { 'Content-type': 'multipart/form-data' }
            });
            const { result } = api.data;
            if (result) {
                toast.info(modify ? 'Success Update Content' : 'Success Add Content', { ...ToastSetting.default });
                if (modify) {
                    getData();
                } else {
                    navigate('/content/music/list');
                }
            } else {
                toast.info('Error', { ...ToastSetting.default });
            }
        } catch (e) {
            console.log('e', e);
        } finally {
            setLoadingOpen(false);
        }
    };

    const deleteData = async () => {
        const api = await axiosService.post('/content/music/delete', { idx });
        const { result, data } = api.data;
        if (result) {
            setDeleteOpen(false);
            navigate('/content/music/list');
        }
    };
    const parameterCheck = () => (searchParams.get('ct') ? Number(searchParams.get('ct')) : 0);
    const [imageURL, setImageURL] = useState(null);
    const [modify, setModify] = useState(false);
    const [files, selectFiles] = useFileUpload();
    const [file2, selectFiles2] = useFileUpload();
    const [category, setCategory] = useState<CategoryListType[]>([]);
    const [categoryIdx, setCategoryIdx] = useState<number>(parameterCheck());
    // @ts-ignore
    console.log("import ReactAudioPlayer from 'react-audio-player';", files);

    const getDataCategory = async () => {
        const response = await axiosService.post('/content/category/list');
        const { result, list } = response.data;
        if (result) {
            setCategory([...list]);
        }
    };

    const fileSource = (file: any) => {
        if (file && file[0]) {
            return file[0].source;
        } else {
            return file?.source;
        }
    };

    const fileSourceBolb = (file: any) => {
        if (file && file[0]) {
            return file[0].file;
        } else {
            return file.file;
        }
    };

    const handleLoadMetadata = (meta) => {
        const { duration: d } = meta.target;
        console.log(d);
        setDuration(Math.round(d));
    };
    const typeHandleChange = (type) => {
        const value = type.target.value;
        setCategoryIdx(value);
    };
    const [init, setInit] = useState('');
    console.log('init', init);
    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item style={{ marginBottom: '5px' }}>
                            <Typography variant="h3">{modify ? 'Update Content' : 'Add Content'}</Typography>
                        </Grid>
                    </Grid>
                }
                content={false}
            >
                <form onSubmit={formik.handleSubmit}>
                    <Grid container p={2} spacing={gridSpacing}>
                        <Grid item xs={12} md={6} container spacing={gridSpacing}>
                            <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                                <Typography variant={'h4'}>MainImage</Typography>
                                <Typography variant={'h5'}>250 * 250 </Typography>
                                <ImageUploader onChange={imageHandler} imageURL={imageURL} onCancel={onCancel} width={250} height={250} />
                            </Grid>
                            <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                                <TextField
                                    id="title"
                                    fullWidth
                                    label="Title"
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.title && Boolean(formik.errors.title)}
                                    helperText={formik.touched.title && formik.errors.title}
                                />
                            </Grid>
                            <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                                <TextField
                                    id="subTitle"
                                    fullWidth
                                    label="Sub title"
                                    value={formik.values.subTitle}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.subTitle && Boolean(formik.errors.subTitle)}
                                    helperText={formik.touched.subTitle && formik.errors.subTitle}
                                />
                            </Grid>

                            <Tabs
                                value={value}
                                indicatorColor={'primary'}
                                onChange={handleChange}
                                sx={{
                                    mb: 3,
                                    minHeight: 'auto',
                                    '& button': {
                                        minWidth: 100
                                    },
                                    '& a': {
                                        minHeight: 'auto',
                                        minWidth: 10,
                                        py: 1.5,
                                        px: 1,
                                        mr: 2.25,
                                        color: 'grey.600'
                                    },
                                    '& a.Mui-selected': {
                                        color: 'primary.main'
                                    }
                                }}
                                aria-label="simple tabs example"
                                variant="scrollable"
                            >
                                <Tab component={Link} to="#" label="En" {...a11yProps(0)} />
                                <Tab component={Link} to="#" label="Hans" {...a11yProps(1)} />
                                <Tab component={Link} to="#" label="Hant" {...a11yProps(2)} />
                                <Tab component={Link} to="#" label="Japan" {...a11yProps(3)} />
                                <Tab component={Link} to="#" label="Korea" {...a11yProps(4)} />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        '& .rdw-editor-wrapper': {
                                            bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : theme.palette.background.paper,
                                            border: '1px solid',
                                            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
                                            borderRadius: '12px',
                                            overflow: 'hidden',
                                            '& .rdw-editor-main': {
                                                px: 2,
                                                py: 0.5,
                                                border: 'none',
                                                minHeight: '300px'
                                            },
                                            '& .rdw-editor-toolbar': {
                                                pt: 1.25,
                                                border: 'none',
                                                borderBottom: '1px solid',
                                                borderColor:
                                                    theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
                                                bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                                '& .rdw-option-wrapper': {
                                                    bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'grey.900'
                                                },

                                                '& .rdw-dropdown-wrapper': {
                                                    bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'grey.900',
                                                    '& .rdw-dropdown-selectedtext': {
                                                        color: theme.palette.mode === 'dark' ? 'dark.dark' : 'grey.900'
                                                    }
                                                }
                                            }
                                        }
                                    }}
                                >
                                    <Stack spacing={gridSpacing}>
                                        {modify && init !== '' ? (
                                            <ReactDraftWysiwyg init={init} value={content} setValue={setContent} />
                                        ) : (
                                            <ReactDraftWysiwyg init={init} value={content} setValue={setContent} />
                                        )}
                                    </Stack>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        '& .rdw-editor-wrapper': {
                                            bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : theme.palette.background.paper,
                                            border: '1px solid',
                                            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
                                            borderRadius: '12px',
                                            overflow: 'hidden',
                                            '& .rdw-editor-main': {
                                                px: 2,
                                                py: 0.5,
                                                border: 'none',
                                                minHeight: '300px'
                                            },
                                            '& .rdw-editor-toolbar': {
                                                pt: 1.25,
                                                border: 'none',
                                                borderBottom: '1px solid',
                                                borderColor:
                                                    theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
                                                bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                                '& .rdw-option-wrapper': {
                                                    bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'grey.900'
                                                },

                                                '& .rdw-dropdown-wrapper': {
                                                    bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'grey.900',
                                                    '& .rdw-dropdown-selectedtext': {
                                                        color: theme.palette.mode === 'dark' ? 'dark.dark' : 'grey.900'
                                                    }
                                                }
                                            }
                                        }
                                    }}
                                >
                                    <Stack spacing={gridSpacing}>
                                        {modify && contentHansInit !== '' ? (
                                            <ReactDraftWysiwyg init={contentHansInit} value={contentHans} setValue={setContentHans} />
                                        ) : (
                                            <ReactDraftWysiwyg init={contentHansInit} value={contentHans} setValue={setContentHans} />
                                        )}
                                    </Stack>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        '& .rdw-editor-wrapper': {
                                            bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : theme.palette.background.paper,
                                            border: '1px solid',
                                            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
                                            borderRadius: '12px',
                                            overflow: 'hidden',
                                            '& .rdw-editor-main': {
                                                px: 2,
                                                py: 0.5,
                                                border: 'none',
                                                minHeight: '300px'
                                            },
                                            '& .rdw-editor-toolbar': {
                                                pt: 1.25,
                                                border: 'none',
                                                borderBottom: '1px solid',
                                                borderColor:
                                                    theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
                                                bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                                '& .rdw-option-wrapper': {
                                                    bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'grey.900'
                                                },

                                                '& .rdw-dropdown-wrapper': {
                                                    bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'grey.900',
                                                    '& .rdw-dropdown-selectedtext': {
                                                        color: theme.palette.mode === 'dark' ? 'dark.dark' : 'grey.900'
                                                    }
                                                }
                                            }
                                        }
                                    }}
                                >
                                    <Stack spacing={gridSpacing}>
                                        {modify && contentHantInit !== '' ? (
                                            <ReactDraftWysiwyg init={contentHantInit} value={contentHant} setValue={setContentHant} />
                                        ) : (
                                            <ReactDraftWysiwyg init={contentHantInit} value={contentHant} setValue={setContentHant} />
                                        )}
                                    </Stack>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        '& .rdw-editor-wrapper': {
                                            bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : theme.palette.background.paper,
                                            border: '1px solid',
                                            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
                                            borderRadius: '12px',
                                            overflow: 'hidden',
                                            '& .rdw-editor-main': {
                                                px: 2,
                                                py: 0.5,
                                                border: 'none',
                                                minHeight: '300px'
                                            },
                                            '& .rdw-editor-toolbar': {
                                                pt: 1.25,
                                                border: 'none',
                                                borderBottom: '1px solid',
                                                borderColor:
                                                    theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
                                                bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                                '& .rdw-option-wrapper': {
                                                    bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'grey.900'
                                                },

                                                '& .rdw-dropdown-wrapper': {
                                                    bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'grey.900',
                                                    '& .rdw-dropdown-selectedtext': {
                                                        color: theme.palette.mode === 'dark' ? 'dark.dark' : 'grey.900'
                                                    }
                                                }
                                            }
                                        }
                                    }}
                                >
                                    <Stack spacing={gridSpacing}>
                                        {modify && contentJaInit !== '' ? (
                                            <ReactDraftWysiwyg init={contentJaInit} value={contentJa} setValue={setContentJa} />
                                        ) : (
                                            <ReactDraftWysiwyg init={contentJaInit} value={contentJa} setValue={setContentJa} />
                                        )}
                                    </Stack>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        '& .rdw-editor-wrapper': {
                                            bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : theme.palette.background.paper,
                                            border: '1px solid',
                                            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
                                            borderRadius: '12px',
                                            overflow: 'hidden',
                                            '& .rdw-editor-main': {
                                                px: 2,
                                                py: 0.5,
                                                border: 'none',
                                                minHeight: '300px'
                                            },
                                            '& .rdw-editor-toolbar': {
                                                pt: 1.25,
                                                border: 'none',
                                                borderBottom: '1px solid',
                                                borderColor:
                                                    theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
                                                bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                                '& .rdw-option-wrapper': {
                                                    bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'grey.900'
                                                },

                                                '& .rdw-dropdown-wrapper': {
                                                    bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'grey.900',
                                                    '& .rdw-dropdown-selectedtext': {
                                                        color: theme.palette.mode === 'dark' ? 'dark.dark' : 'grey.900'
                                                    }
                                                }
                                            }
                                        }
                                    }}
                                >
                                    <Stack spacing={gridSpacing}>
                                        {modify && contentKoInit !== '' ? (
                                            <ReactDraftWysiwyg init={contentKoInit} value={contentKo} setValue={setContentKo} />
                                        ) : (
                                            <ReactDraftWysiwyg init={contentKoInit} value={contentKo} setValue={setContentKo} />
                                        )}
                                    </Stack>
                                </Grid>
                            </TabPanel>
                            <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                                <TextField
                                    id="lank"
                                    fullWidth
                                    type={'number'}
                                    label="Rank"
                                    value={formik.values.lank}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.lank && Boolean(formik.errors.lank)}
                                    helperText={formik.touched.lank && formik.errors.lank}
                                />
                            </Grid>
                            <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={use} onChange={() => setUse((p) => !p)} />} label="Use" />
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox checked={recommend} onChange={() => setRecommend((p) => !p)} />}
                                        label="Recommend"
                                    />
                                </FormGroup>
                            </Grid>
                            {recommend && (
                                <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                                    <TextField
                                        id="recommendRank"
                                        fullWidth
                                        type={'number'}
                                        label="Recommend rank"
                                        value={formik.values.recommendRank}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.recommendRank && Boolean(formik.errors.recommendRank)}
                                        helperText={formik.touched.recommendRank && formik.errors.recommendRank}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12} md={5} container>
                            <Grid spacing={1} item xs={12} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
                                <Typography variant={'h4'}>Category Select</Typography>
                                <Grid item mt={'10px'}>
                                    <Select
                                        id="columnId"
                                        name="columnId"
                                        value={categoryIdx}
                                        onChange={typeHandleChange}
                                        placeholder={'select category'}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {category.map((row, index: number) => (
                                            <MenuItem key={`${row.idx}+index`} value={row.idx}>
                                                {row.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </Grid>

                            <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
                                <Typography variant={'h4'}>Main Music</Typography>
                                <Typography variant={'h5'}>.mp3, .wav </Typography>
                                {duration > 0 && <Typography variant={'h5'}>Duration {`${duration}`}</Typography>}
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                    <ReactAudioPlayer src={fileSource(files) || mainUrl} controls onLoadedMetadata={handleLoadMetadata} />
                                    <Button
                                        onClick={() =>
                                            selectFiles({ accept: 'audio/*', multiple: false }, (te: any) => {
                                                const { name, size, source, file } = te;
                                                console.log('Files Selected', { name, size, source, file });
                                            })
                                        }
                                    >
                                        Upload
                                    </Button>
                                </div>
                            </Grid>
                            <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
                                <Typography variant={'h4'}>Brain Music</Typography>
                                <Typography variant={'h5'}>.mp3, .wav </Typography>
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                    <ReactAudioPlayer src={fileSource(file2) || brainUrl} controls />
                                    <Button
                                        onClick={() =>
                                            selectFiles2({ accept: 'audio/*', multiple: false }, (te: any) => {
                                                const { name, size, source, file } = te;
                                                console.log('Files Selected', { name, size, source, file });
                                            })
                                        }
                                    >
                                        Upload
                                    </Button>
                                </div>
                            </Grid>
                            <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'} />

                            <Grid item xs={12}>
                                <Stack direction="row">
                                    <AnimateButton>
                                        <Button variant="contained" type={'submit'}>
                                            {modify ? 'Update' : 'Add'} CONTENT
                                        </Button>
                                    </AnimateButton>
                                    {modify && (
                                        <AnimateButton>
                                            <Button
                                                onClick={() => setDeleteOpen(true)}
                                                style={{ marginLeft: '20px' }}
                                                color={'error'}
                                                variant="contained"
                                                type={'button'}
                                            >
                                                DELETE
                                            </Button>
                                        </AnimateButton>
                                    )}
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </MainCard>
            <AlertDialog
                title={modify ? 'Update Content' : 'Create Content'}
                content={modify ? 'Do you want to update Content?' : `Do you want to create Content?`}
                open={alertOpen}
                handleClose={setAlertOpen}
                handleSuccess={() => insertFetch()}
            />
            <AlertDialog
                title={'Delete Content'}
                content={'Do you want to Delete Content?'}
                open={deleteOpen}
                handleClose={setDeleteOpen}
                handleSuccess={() => deleteData()}
            />
            <AlertDialog
                title={'Uploading'}
                content={`${progress}%`}
                open={loadingOpen}
                handleClose={setLoadingOpen}
                handleSuccess={() => null}
                hideContent
            />
        </>
    );
};

export default MusicAdd;
