import React from 'react';
import { Chip } from '@mui/material';
import { useTheme } from '@mui/system';

interface Props {
    type: number | null;
}

const PickTypeCard = ({ type }: Props) => {
    const theme = useTheme();
    switch (type) {
        case 1:
            return (
                <Chip
                    label="booked"
                    size="small"
                    sx={{
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
                        color: theme.palette.warning.dark
                    }}
                />
            );
        case 2:
            return (
                <Chip
                    label="assigned"
                    size="small"
                    sx={{
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                        color: theme.palette.success.dark
                    }}
                />
            );
        case 3:
            return (
                <Chip
                    label="collected"
                    size="small"
                    sx={{
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light + 80,
                        color: theme.palette.orange.dark
                    }}
                />
            );
        case 4:
            return (
                <Chip
                    label="delivered"
                    size="small"
                    sx={{
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light + 80,
                        color: theme.palette.orange.dark
                    }}
                />
            );
        case 5:
            return (
                <Chip
                    label="credited"
                    size="small"
                    sx={{
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light + 80,
                        color: theme.palette.orange.dark
                    }}
                />
            );
        default:
            return (
                <Chip
                    label="booked"
                    size="small"
                    sx={{
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
                        color: theme.palette.warning.dark
                    }}
                />
            );
    }
};

export const RouteTypeCard = ({ type }: Props) => {
    const theme = useTheme();
    switch (type) {
        case 1:
            return (
                <Chip
                    label="Processing"
                    size="small"
                    sx={{
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
                        color: theme.palette.warning.dark
                    }}
                />
            );
        case 2:
            return (
                <Chip
                    label="Processing"
                    size="small"
                    sx={{
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                        color: theme.palette.success.dark
                    }}
                />
            );
        case 3:
            return (
                <Chip
                    label="Completed"
                    size="small"
                    sx={{
                        background: 'lightgreen',
                        color: '#222'
                    }}
                />
            );
        default:
            return (
                <Chip
                    label="Wait"
                    size="small"
                    sx={{
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
                        color: theme.palette.warning.dark
                    }}
                />
            );
    }
};

export default PickTypeCard;
