// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

// types
interface Props {
    title: string;
    content: string;
    open: boolean;
    handleClose: Dispatch<SetStateAction<Boolean>>;
    handleSuccess: () => void;
    hideContent?: boolean;
}

export default function AlertDialog({ title, content, open, handleClose, handleSuccess, hideContent }: Props) {
    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            keepMounted
            fullWidth
            maxWidth="xs"
            aria-labelledby="item-delete-title"
            aria-describedby="item-delete-description"
        >
            <DialogTitle id="item-delete-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="item-delete-description">{content}</DialogContentText>
            </DialogContent>
            {!hideContent && (
                <DialogActions sx={{ mr: 2 }}>
                    <Button onClick={() => handleClose(false)} color="error">
                        Cancel
                    </Button>
                    <Button variant="contained" size="small" onClick={() => handleSuccess()} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
}
