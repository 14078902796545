import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import {
    Box,
    Button,
    CardContent,
    Checkbox,
    FormControlLabel,
    Grid,
    InputAdornment,
    Menu,
    MenuItem,
    OutlinedInput,
    Pagination,
    Radio,
    Stack,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { IconSearch } from '@tabler/icons';
import MainCard from '../../ui-component/cards/MainCard';
import { jsPDF } from 'jspdf';
import useAxiosServices from '../../hooks/useAxios';
import { useDispatch, useSelector } from 'react-redux';
import '../../assets/scss/Calender.css';
import { LOADING_END, LOADING_START } from '../../store/actions';
import Calendar from 'react-calendar';
import FormControl from '../../ui-component/extended/Form/FormControl';
import * as yup from 'yup';
import { useFormik } from 'formik';
import MaskedInput from 'react-text-mask';
import AnimateButton from '../../ui-component/extended/AnimateButton';
import { format } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';
import PushApp from '../userPage/push.app';
import PushMail from '../userPage/push.mail';
import { DefaultRootStateProps } from '../../types';
import PhoneAndroidTwoToneIcon from '@mui/icons-material/PhoneAndroidTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import PushAppAll from './push.appAll';
import PushMailAll from './push.mailAll';
import PushWrapper from './push.wrapper';

const tabsOption = [
    {
        label: 'Push',
        icon: <PhoneAndroidTwoToneIcon />,
        caption: 'Send notifications through the app'
    },
    {
        label: 'Mail',
        icon: <EmailTwoToneIcon />,
        caption: 'Send notifications to your email'
    }
];

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

function TabPanel(props: { children: React.ReactElement; value: number; index: number }) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <div>{children}</div>}
        </div>
    );
}

const PushDetail = () => {
    const theme = useTheme();
    const customization = useSelector((state: DefaultRootStateProps) => state.customization);
    const [value, setValue] = React.useState<number>(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const [type, setType] = useState(0);

    return (
        <MainCard
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3">Messaging</Typography>
                    </Grid>
                </Grid>
            }
            content={false}
        >
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} lg={4}>
                    <Grid item xs={12} px={2}>
                        <FormControlLabel
                            checked={type === 0}
                            control={
                                <Radio
                                    onClick={() => setType(0)}
                                    checked={type === 0}
                                    sx={{
                                        color: 'primary.main',
                                        '&.Mui-checked': { color: 'primary.main' }
                                    }}
                                />
                            }
                            label={'User'}
                        />
                    </Grid>
                    <CardContent>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            orientation="vertical"
                            variant="scrollable"
                            sx={{
                                '& .MuiTabs-flexContainer': {
                                    borderBottom: 'none'
                                },
                                '& button': {
                                    color: theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[600],
                                    minHeight: 'auto',
                                    minWidth: '100%',
                                    py: 1.5,
                                    px: 2,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                    textAlign: 'left',
                                    justifyContent: 'flex-start',
                                    borderRadius: `${customization.borderRadius}px`
                                },
                                '& button.Mui-selected': {
                                    color: theme.palette.primary.main,
                                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50]
                                },
                                '& button > svg': {
                                    marginBottom: '0px !important',
                                    marginRight: 1.25,
                                    marginTop: 1.25,
                                    height: 20,
                                    width: 20
                                },
                                '& button > div > span': {
                                    display: 'block'
                                },
                                '& > div > span': {
                                    display: 'none'
                                }
                            }}
                        >
                            {tabsOption.map((tab, index) => (
                                <Tab
                                    key={index}
                                    icon={tab.icon}
                                    label={
                                        <Grid container direction="column">
                                            <Typography variant="subtitle1" color="inherit">
                                                {tab.label}
                                            </Typography>
                                            <Typography component="div" variant="caption" sx={{ textTransform: 'capitalize' }}>
                                                {tab.caption}
                                            </Typography>
                                        </Grid>
                                    }
                                    {...a11yProps(index)}
                                />
                            ))}
                        </Tabs>
                    </CardContent>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <CardContent
                        sx={{
                            height: '100%'
                        }}
                    >
                        <TabPanel value={value} index={0}>
                            <PushAppAll type={type} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <PushMailAll type={type} />
                        </TabPanel>
                    </CardContent>
                </Grid>
            </Grid>
            <PushWrapper />
        </MainCard>
    );
};

export default PushDetail;
