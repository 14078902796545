import React, { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Button,
    Checkbox,
    Chip,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Pagination,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';

// project imports
import Avatar from 'ui-component/extended/Avatar';
import axios from 'utils/axios';
import { userListProfile, UserProfile } from '_mockApis/user-profile/types';

// assets
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import MoreTwoToneIcon from '@mui/icons-material/MoreTwoTone';
import useAxios from '../../hooks/useAxios';

import FaceIcon from '@mui/icons-material/Face';
import { gridSpacing } from '../../store/constant';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import UserDownload from '../../components/csv/user.download';
import Select from '@mui/material/Select';
import { ContentListType } from '../../types/content.types';
import { useNavigate } from 'react-router-dom';
import ContentRow from './content.row';
// ==============================|| USER LIST 1 ||============================== //

const ContentList = () => {
    const theme = useTheme();
    const { axiosService } = useAxios();
    const [data, setData] = React.useState<ContentListType[]>([]);
    const [recommend, setRecommend] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [listParam, setListParam] = useState<{
        skip: number;
        take: number;
        nameSort: 'ASC' | 'DESC';
        creditSort: 'ASC' | 'DESC';
    }>({
        skip: 0,
        take: 10,
        nameSort: 'ASC',
        creditSort: 'ASC'
    });
    const [paging, setPaging] = useState({
        allPage: 0,
        count: 0
    });
    const getData = async () => {
        const response = await axiosService.post('/content/music/list', { ...listParam, recommend });
        setData(response.data.list);
        setPaging((p) => ({
            ...p,
            allPage: response.data.allPage,
            count: response.data.count
        }));
    };

    React.useEffect(() => {
        getData();
    }, [listParam, recommend]);

    const pageChange = (event, page) => {
        setListParam((p) => ({ ...p, skip: page - 1 }));
    };

    const typeHandleChange = (type) => {
        const value = type.target.value;
        setListParam((p) => ({ ...p, type: value }));
    };
    const navigation = useNavigate();
    return (
        <>
            <Grid container flexDirection={'row-reverse'}>
                <Grid item>
                    <Button onClick={() => navigation('/content/music/add/new')}> Add Content</Button>
                </Grid>
            </Grid>
            <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={recommend} onChange={() => setRecommend((p) => !p)} />}
                        label="Recommend"
                    />
                </FormGroup>
            </Grid>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Title</TableCell>
                            <TableCell>Image</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>rank</TableCell>
                            <TableCell>duration</TableCell>
                            <TableCell>status</TableCell>
                            <TableCell>Recommend</TableCell>
                            <TableCell align="center" sx={{ pr: 3 }}>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{data && data.map((row, index) => <ContentRow row={row} index={index} />)}</TableBody>
                </Table>
            </TableContainer>
            <Grid item xs={12} sx={{ p: 3 }}>
                <Grid container justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Pagination page={listParam.skip + 1} count={paging.allPage} color="secondary" onChange={pageChange} />
                    </Grid>
                    <Grid item>
                        <Button size="large" sx={{ color: theme.palette.grey[900] }} color="secondary" onClick={handleClick}>
                            10 Rows
                        </Button>
                        <UserDownload type={1} skip={listParam.skip} take={listParam.take} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ContentList;
