import React, { useEffect, useState } from 'react';
import {
    Box,
    FormControl,
    InputLabel,
    NativeSelect,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import ContentRow from '../../content/content.row';
import { ContentListType, IRecentListType, IRecentListTypeCount } from '../../../types/content.types';
import UserContentRow from './user.content.row';
import UserRecentRow from './user.recent.row';
import { recentPlayCount } from '../../../utils/common';

interface IProps {
    data: IRecentListTypeCount[];
    type: 'book' | 'recent';
}
const UserRecentTable = ({ data, type }: IProps) => {
    const [sort, setSort] = useState('DESC');
    const [list, setList] = useState<IRecentListTypeCount[]>([]);
    const handleSortList = () => {
        const result = data.sort((prev, recent) => {
            if (prev.count > recent.count) {
                return sort === 'ASC' ? 1 : -1;
            }
            if (prev.count < recent.count) {
                return sort === 'ASC' ? -1 : -1;
            }
            // a must be equal to b
            return 0;
        });
        setList([...result]);
    };
    useEffect(() => {
        handleSortList();
    }, [data, sort]);
    const handleDateSort = (event) => {
        event?.target.value && setSort(event?.target.value);
    };
    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                    <Typography variant="h5" noWrap>
                        All Play : {recentPlayCount(list)}
                    </Typography>
                </Box>
                <FormControl sx={{ width: '150px' }}>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Sorting by play
                    </InputLabel>
                    <NativeSelect
                        id="SortingByplay"
                        name="SortingByplay"
                        value={sort}
                        onChange={(e) => handleDateSort(e)}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        {[
                            { label: 'DESC', value: 'DESC' },
                            { label: 'ASC', value: 'ASC' }
                        ].map(({ value: monthValue, label }, index: number) => (
                            <option key={index} value={monthValue}>
                                {label}
                            </option>
                        ))}
                    </NativeSelect>
                </FormControl>
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Image</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Play</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{data && data.map((row, index) => <UserRecentRow row={row} index={index} />)}</TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default UserRecentTable;
