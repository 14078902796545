import React, { useState } from 'react';
import { driverListProfile, userListProfile } from '../../_mockApis/user-profile/types';
import { Chip, Grid, IconButton, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import Avatar from '../../ui-component/extended/Avatar';
import FaceIcon from '@mui/icons-material/Face';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import MoreTwoToneIcon from '@mui/icons-material/MoreTwoTone';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

interface props {
    row: driverListProfile;
    index: number;
}

const DepotRows = ({ row, index }: props) => {
    const theme = useTheme();
    const navigation = useNavigate();
    const handlerGoToProfile = (idx: number) => {
        navigation(`/user/default/${idx}`);
    };
    return (
        <TableRow hover key={index}>
            <TableCell sx={{ pl: 3 }}>{row.userName}</TableCell>
            <TableCell sx={{ pl: 3 }}>{row.userId}</TableCell>
            <TableCell sx={{ pl: 3 }}>{row.userEmail}</TableCell>
            <TableCell>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs zeroMinWidth>
                        <Typography align="left" variant="subtitle1" component="div">
                            {row.userName}{' '}
                        </Typography>
                        <Typography align="left" variant="subtitle2" noWrap>
                            {row.userNickName}
                        </Typography>
                        <Typography align="left" variant="subtitle2" noWrap>
                            {row.userEmail}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>{row.userZip}</TableCell>
            <TableCell>
                <Typography align="left" variant="subtitle2" noWrap>
                    {row.userAddress}
                </Typography>
            </TableCell>
            <TableCell align="center" sx={{ pr: 3 }}>
                <Stack direction="row" justifyContent="center" alignItems="center">
                    <Tooltip placement="top" title="More">
                        <IconButton
                            onClick={() => handlerGoToProfile(row.idx)}
                            color="primary"
                            sx={{
                                color: theme.palette.orange.dark,
                                borderColor: theme.palette.orange.main,
                                '&:hover ': { background: theme.palette.orange.light }
                            }}
                            size="large"
                        >
                            <MoreTwoToneIcon sx={{ fontSize: '1.1rem' }} />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </TableCell>
        </TableRow>
    );
};

export default DepotRows;
