import React, { useState, CSSProperties } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material-ui
import { Theme, useTheme } from '@mui/material/styles';
import {
    Box,
    ButtonBase,
    Chip,
    Collapse,
    Grid,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';

// third-party

// project imports
import { SNACKBAR_OPEN, DELETE_STORY } from 'store/actions';

// assets
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';

import { useNavigate } from 'react-router-dom';
import { TransactionListTypes } from '../../types/charity';
import { TransactionTypeCase } from '../../utils/common';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface Props {
    value: TransactionListTypes;
    index: number;
}

const TransactionRow = ({ value, index }: Props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigation = useNavigate();
    const handlerGoToProfile = (idx: number) => {
        navigation(`/user/default/${idx}`);
    };
    return (
        <>
            <TableRow hover>
                <TableCell sx={{ pl: 3, minWidth: 40, width: 40, height: 46 }}>{value.idx}</TableCell>
                <TableCell component="th" scope="row">
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs zeroMinWidth>
                            <Typography display={'flex'} mb={1} align="left" variant="subtitle1" component="div">
                                <Link
                                    onClick={() => navigation(`/user/default/${value.userIdx}`)}
                                    underline="hover"
                                    color="default"
                                    sx={{
                                        overflow: 'hidden',
                                        display: 'block',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        ':hover': { color: 'primary.main' },
                                        cursor: 'pointer'
                                    }}
                                >
                                    {value.user?.userFirstName} {value.user?.userName}
                                </Link>
                                {value.user?.userStatus === 1 && (
                                    <CheckCircleIcon sx={{ ml: 1, color: 'success.dark', width: 14, height: 14 }} />
                                )}
                            </Typography>
                            <Typography align="left" variant="subtitle2" noWrap>
                                {value.user?.userNickName}
                            </Typography>
                            <Typography align="left" variant="subtitle2" noWrap>
                                {value.user?.userEmail}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Typography align="left" variant="subtitle2" noWrap>
                        ${value.amount}.00
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography align="left" variant="subtitle2" noWrap>
                        {TransactionTypeCase(value.type)}
                    </Typography>
                </TableCell>
                <TableCell sx={{ width: 30, minWidth: 60 }}>
                    {/* <ButtonBase */}
                    {/*    className="more-button" */}
                    {/*    sx={{ borderRadius: '12px' }} */}
                    {/*    onClick={handleClick} */}
                    {/*    aria-controls="menu-comment" */}
                    {/*    aria-haspopup="true" */}
                    {/* > */}
                    {/*    <IconButton component="span" size="small" disableRipple> */}
                    {/*        <MoreVertTwoToneIcon fontSize="inherit" /> */}
                    {/*    </IconButton> */}
                    {/* </ButtonBase> */}
                    <Menu
                        id="menu-comment"
                        // @ts-ignore
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        variant="selectedMenu"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                navigate(`/routing/detail/${value.idx}`);
                            }}
                        >
                            More
                        </MenuItem>
                    </Menu>
                </TableCell>
            </TableRow>
        </>
    );
};

export default TransactionRow;
