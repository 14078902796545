import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import DepotListWrapper from '../views/userPage/DepotListWrapper';
import CreateDepot from '../views/depot/create.depot';
import CharityPageWrapper from '../views/charity/charity.pageWrapper';
import CharityAdd from '../views/charity/charity.add';
import CharityUpdate from '../views/charity/charity.update';
import QrPage from '../views/settings/QrPage';
import TransactionWrapper from '../views/transaction/transactionWrapper';
import CommonSetting from '../views/settings/CommonSetting';
import SuburbSettingList from '../views/settings/suburb.setting';
import SuburbDetail from '../views/settings/suburb.detail';
import QrList from '../views/settings/QrList';
import DriverListWrapper from '../views/userPage/DriverListWrapper';
import OrderList from '../views/order/order.list';
import OrderStatstics from '../views/order/order.statstics';
import ShareStatstics from '../views/stat/share.statstics';
import PushDetail from '../views/settings/push.detail';
import AdminProfile from '../views/userProfile/admin.profile';
import SuburbStastics from '../views/stat/suburb.statstics';
import MessageWrapper from '../views/message/message.wrapper';
import WaitDriverList from '../views/pickPage/wait.driverList';
import WaitDepotList from '../views/pickPage/wait.depotList';
import CategoryList from '../views/content/category.list';
import CategoryAdd from '../views/content/category.add';
import ContentList from '../views/content/content.list';
import MusicAdd from '../views/content/music.add';
import ContentStatPage from '../views/stat/content.stat';
import ContentCategoryList from '../views/content.category/content.category.list';

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const DefaultDashBoard = Loadable(lazy(() => import('views/dashboard')));
const UserList = Loadable(lazy(() => import('views/userPage/UserListWrapper')));

const RouteDetail = Loadable(lazy(() => import('views/pick/RouteDetail')));
const PickList = Loadable(lazy(() => import('views/pickPage/PickPageWrapper')));
const UserProfile = Loadable(lazy(() => import('views/userProfile')));
const RoutingList = Loadable(lazy(() => import('views/pick-routing-pages/RoutingList')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dash-board',
            element: <DefaultDashBoard />
        },
        {
            path: '/user/default',
            element: <UserList />
        },
        {
            path: '/user/default/:id',
            element: <UserProfile />
        },
        {
            path: '/content/category/list',
            element: <CategoryList />
        },
        { path: '/content/category/add/:idx', element: <CategoryAdd /> },
        { path: '/content/music/add/:idx', element: <MusicAdd /> },
        { path: '/content/music/list', element: <ContentList /> },
        { path: '/content/music/category/:idx', element: <ContentCategoryList /> },
        {
            path: '/user/driver',
            element: <DriverListWrapper wait={1} />
        },
        {
            path: '/user/driverWait',
            element: <DriverListWrapper wait={2} />
        },
        {
            path: '/user/depot',
            element: <DepotListWrapper />
        },
        {
            path: '/user/CreateDepot',
            element: <CreateDepot />
        },
        {
            path: '/routing/list',
            element: <RoutingList />
        },
        {
            path: '/routing/detail/:routeId',
            element: <RouteDetail />
        },
        {
            path: '/routing/new/',
            element: <RouteDetail />
        },
        {
            path: '/pick/list',
            element: <PickList />
        },
        {
            path: '/pick/list/driverWait',
            element: <WaitDriverList />
        },
        {
            path: '/pick/list/depotWait',
            element: <WaitDepotList />
        },
        {
            path: '/charity/list',
            element: <CharityPageWrapper />
        },
        {
            path: '/charity/add',
            element: <CharityAdd />
        },
        {
            path: '/charity/update/:idx',
            element: <CharityUpdate />
        },
        {
            path: '/setting/qrList',
            element: <QrList />
        },
        {
            path: '/setting/qrCode',
            element: <QrPage />
        },
        {
            path: '/setting/settings',
            element: <CommonSetting />
        },
        {
            path: '/setting/suburb',
            element: <SuburbSettingList />
        },
        {
            path: '/setting/suburb/new',
            element: <SuburbDetail />
        },
        {
            path: '/setting/suburb/:idx',
            element: <SuburbDetail />
        },
        {
            path: '/transaction/list',
            element: <TransactionWrapper />
        },
        {
            path: '/order/list',
            element: <OrderList />
        },
        {
            path: '/order/financial',
            element: <OrderStatstics />
        },
        {
            path: '/setting/messaging',
            element: <PushDetail />
        },
        {
            path: '/share/financial',
            element: <ShareStatstics />
        },
        {
            path: '/admin/:id',
            element: <AdminProfile />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        { path: '/stat/postcode', element: <SuburbStastics /> },
        { path: '/stat/content', element: <ContentStatPage /> },
        { path: '/messaging/setting', element: <MessageWrapper /> }
    ]
};

export default MainRoutes;
