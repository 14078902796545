import { useSelector } from 'react-redux';

import { ThemeProvider, Theme } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-medium-image-zoom/dist/styles.css';
// routing
import Routes from 'routes';

// store
import { DefaultRootStateProps } from 'types';

import themes from 'themes';

import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import Snackbar from 'ui-component/extended/Snackbar';
import { JWTProvider } from 'contexts/JWTContext';
import LoadingScreen from './ui-component/loading.screen';

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state: DefaultRootStateProps) => state.customization);
    const loading = useSelector((state: DefaultRootStateProps) => state.customization.loading);
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                {/* RTL layout */}
                {/* <RTLLayout> */}
                <Locales>
                    <NavigationScroll>
                        <JWTProvider>
                            <>
                                <Routes />
                                <Snackbar />
                            </>
                        </JWTProvider>
                    </NavigationScroll>
                </Locales>
                {/* </RTLLayout> */}
            </ThemeProvider>
            <ToastContainer />
            {loading && <LoadingScreen />}
        </StyledEngineProvider>
    );
};

export default App;
