import React, { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT, REGISTER } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';
import { initialLoginContextProps, KeyedObject } from 'types';
import { JWTContextType } from 'types/auth';
import { Cookies } from 'react-cookie';
import useAxiosServices from '../hooks/useAxios';
import { useNavigate } from 'react-router-dom';

const chance = new Chance();

// constant
const initialState: initialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken: (st: string) => boolean = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded: KeyedObject = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken?: string | null) => !!serviceToken;
// JWTContextType
const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };
    const { axiosService, serviceToken } = useAxiosServices(logout);

    useEffect(() => {
        const init = async () => {
            try {
                if (serviceToken) {
                    setSession(serviceToken);
                    const response = await axiosService.post('/users/profile');
                    const { data } = response.data;
                    // const { user } = response.data;
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: data
                        }
                    });
                    // navigate('dash-board', { replace: true });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const login = async (email: string, password: string) => {
        const response = await axiosService.post('/users/login/admin', { username: email, password });
        const { data } = response.data;
        console.log('data', data);
        setSession(serviceToken);
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user: data
            }
        });
    };

    const register = async (email: string, password: string, firstName: string, lastName: string) => {
        console.log('register...');
    };

    const resetPassword = (email: string) => console.log(email);

    const updateProfile = (user) => {
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user
            }
        });
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>
    );
};

export default JWTContext;
