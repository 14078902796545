import { IRecentListType, IRecentListTypeCount } from '../types/content.types';

const Common = {};

export const ToastSetting = {
    default: {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    },
    top: {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    }
};

export const TransactionTypeCase = (status: number) => {
    switch (status) {
        case 0:
            return 'GiftPay';
        case 1:
            return 'Paypal';
        case 2:
            return 'Donate';
        case 3:
            return 'Bank';
        default:
            return '';
    }
};

export const BookStatusCase = (status: number | undefined) => {
    switch (status) {
        case 1:
            return 'Booked';
        case 2:
            return 'Assigned';
        case 3:
            return 'Collected';
        case 4:
            return 'Delivered';
        case 5:
            return 'Credited';
        default:
            return '';
    }
};

export const TransactionBagCase = (status: number) => {
    switch (status) {
        case 1:
            return 'Recan bags';
        case 2:
            return 'Non Recan bags';
        case 3:
            return 'Wheelie Bin';
        case 4:
            return 'Bulk Bag';
        default:
            return '';
    }
};

export const addressNullCheck = (text) => {
    if (!text) return '';
    return ` ,${text}`;
};

export const addressFormat = (postCode, addressMore) => {
    if (!addressMore || addressMore === '') return '';
    if (!postCode) return '';
    return `${addressMore}${addressNullCheck(postCode)}`;
};

const groupBy = <T, K extends keyof T>(array: T[], groupOn: K): Record<string, T[]> => {
    const groupFn = (o: T) => o[groupOn];
    return Object.fromEntries(
        array.reduce((acc, obj) => {
            const groupKey = groupFn(obj);
            return acc.set(groupKey, [...(acc.get(groupKey) || []), obj]);
        }, new Map())
    ) as Record<string, T[]>;
};

export const groupRecentList = (list: IRecentListType[]): IRecentListTypeCount[] => {
    if (!list || list.length === 0) {
        return [];
    }
    const group = groupBy(list, 'musicIdx');
    const result = [];
    for (const [key, value] of Object.entries(group)) {
        if (value && value.length > 0) {
            const data = { ...value[0], count: value.length };
            result.push(data);
        }
    }
    return result;
};
export const recentPlayCount = (list: IRecentListTypeCount[]) => {
    let count = 0;
    for (const row of list) {
        count += row.count;
    }
    return count;
};
export default Common;
