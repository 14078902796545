import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import useAxiosServices from '../../hooks/useAxios';
import MessageContent from './message.content';

function TabPanel(props: { children: React.ReactElement; value: number; index: number }) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`user-tabpanel-${index}`} aria-labelledby={`user-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `user-tab-${index}`,
        'aria-controls': `user-tabpanel-${index}`
    };
}

const MessageWrapper = () => {
    const { axiosService } = useAxiosServices();
    const [type, setType] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue) => {
        setType(newValue);
    };

    const handlerUpdateProfile = (updateData) => {};
    const navigation = useNavigate();

    const [data, setData] = useState({ title: '', content: '', mailContent: '' });

    return (
        <>
            <MainCard title={'Setting Template'}>
                <div>
                    <Tabs
                        value={type}
                        indicatorColor={'primary'}
                        onChange={handleChange}
                        sx={{
                            mb: 3,
                            minHeight: 'auto',

                            '& a.Mui-selected': {
                                color: 'primary.main'
                            }
                        }}
                        aria-label="simple tabs example"
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                    >
                        <Tab wrapped component={Link} to="#" label="After user signup (to user)" {...a11yProps(0)} />
                        <Tab wrapped component={Link} to="#" label="After driver approval (to driver)" {...a11yProps(1)} />
                        <Tab wrapped component={Link} to="#" label="A day before pick up (to user)" {...a11yProps(2)} />
                        <Tab wrapped component={Link} to="#" label="On pick up day (to user)" {...a11yProps(3)} />
                        <Tab wrapped component={Link} to="#" label="After driver picked up (to user)" {...a11yProps(4)} />
                        <Tab wrapped component={Link} to="#" label="After processed by depot (to user)" {...a11yProps(5)} />
                        <Tab wrapped component={Link} to="#" label="After pick up canceled by (to user)" {...a11yProps(6)} />
                        <Tab wrapped component={Link} to="#" label="After order is complete (to user)" {...a11yProps(7)} />
                        <Tab wrapped component={Link} to="#" label="After order cancelled (to user)" {...a11yProps(8)} />
                        <Tab wrapped component={Link} to="#" label="After user book a collection (to user)" {...a11yProps(8)} />
                    </Tabs>
                    <TabPanel value={type} index={0}>
                        <MessageContent type={type} />
                    </TabPanel>
                    <TabPanel value={type} index={1}>
                        <MessageContent type={type} />
                    </TabPanel>
                    <TabPanel value={type} index={2}>
                        <MessageContent type={type} />
                    </TabPanel>
                    <TabPanel value={type} index={3}>
                        <MessageContent type={type} />
                    </TabPanel>
                    <TabPanel value={type} index={4}>
                        <MessageContent type={type} />
                    </TabPanel>
                    <TabPanel value={type} index={5}>
                        <MessageContent type={type} />
                    </TabPanel>
                    <TabPanel value={type} index={6}>
                        <MessageContent type={type} />
                    </TabPanel>
                    <TabPanel value={type} index={7}>
                        <MessageContent type={type} />
                    </TabPanel>
                    <TabPanel value={type} index={8}>
                        <MessageContent type={type} />
                    </TabPanel>
                    <TabPanel value={type} index={9}>
                        <MessageContent type={type} />
                    </TabPanel>
                </div>
            </MainCard>
        </>
    );
};

export default MessageWrapper;
