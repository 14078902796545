import React, { useState } from 'react';
import { useTheme } from '@mui/styles';
import { Button, Grid, InputAdornment, Menu, MenuItem, OutlinedInput, Pagination, TextField, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { IconSearch } from '@tabler/icons';
import MainCard from '../../ui-component/cards/MainCard';
import { jsPDF } from 'jspdf';
import useAxiosServices from '../../hooks/useAxios';
import { useDispatch } from 'react-redux';
import { LOADING_END, LOADING_START } from '../../store/actions';
import { InputOutlined } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';
import { useNavigate } from 'react-router-dom';

const QrPage = () => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const { axiosService } = useAxiosServices();
    const [start, setStart] = useState('100');
    const [endId, setEndId] = useState(100015);
    const SIZE = '300x300';
    const baseURL = 'brokn.in/';
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();

    const getImageSrc = (tagId) => {
        const URL = `https://chart.googleapis.com/chart?chs=${SIZE}&cht=qr&chl=${tagId}&choe=UTF-8`;
        return URL;
    };

    const handlerCatch = () => {
        const sN = Number(start);
        if (isNaN(sN)) {
            alert('Just enter the number of integers');
            return false;
        }
        if (sN <= 0) {
            alert('Please enter numbers above zero.');
            return false;
        }
        if (sN >= 300) {
            alert('Output can be down to up to 300 at a time.');
            return false;
        }

        return true;
    };

    const getCode = async () => {
        try {
            const cc = handlerCatch();
            if (!cc) {
                return false;
            }
            dispatch({ type: LOADING_START });
            const api = await axiosService.post('/pick/qrCode/add', { start: Number(start) });
            const { status, data } = api.data;
            await handleDownload(data);
        } catch (e) {
            console.log(e);
        } finally {
            dispatch({ type: LOADING_END });
        }
    };
    const navigation = useNavigate();
    const handleDownload = (list) => {
        renderImagesPDF(list);
        doc.save('Qrcode.pdf');
        navigation('/setting/qrList');
        toast.info('Success Created', { ...ToastSetting.default });
    };

    const renderImagesPDF = (list: string[]) => {
        let x = 0;
        let y = 10;
        let j = 0;
        let k = 0;
        let items = 0;
        const qrSize = 70;
        const A4pageWidth = 210; // 210mm
        const A4pageHeight = 297; // 297mm
        const vPadding = 10;
        const textPadding = 10;
        for (const row of list) {
            if (items >= 12) {
                doc.addPage();
                x = 0;
                y = 10;
                j = 0;
                k = 0;
                items = 0;
            }
            const imageData = new Image(300, 300);
            imageData.src = getImageSrc(row);
            // doc.text("Scan me", x, y);
            doc.addImage(imageData, 'PNG', x, y, qrSize, qrSize);
            doc.setFontSize(16);
            doc.text(`${row}`, x + textPadding, y + qrSize - 3);

            items++;
            if (x >= A4pageWidth - qrSize) {
                x = 0;
                k = 0;
                y = ++j * qrSize + vPadding;
            } else {
                x = ++k * qrSize;
            }
        }
    };
    return (
        <MainCard
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3">QR Generator</Typography>
                    </Grid>
                </Grid>
            }
            content={false}
        >
            <Grid container alignItems={'center'} justifyContent={'center'} p={10}>
                <Grid item xs={6}>
                    <TextField
                        onChange={(e) => setStart(e.currentTarget.value)}
                        value={start}
                        fullWidth
                        id="Qrcode"
                        label="Enter the number of QR codes"
                    />
                </Grid>
                <Grid item xs={12} pt={10} alignItems={'center'} justifyContent={'center'} display={'flex'}>
                    <Button onClick={() => getCode()}>Generator Code And Download</Button>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default QrPage;
