import React, { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Button,
    Chip,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Pagination,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';

// project imports
import Avatar from 'ui-component/extended/Avatar';
import axios from 'utils/axios';
import { userListProfile, UserProfile } from '_mockApis/user-profile/types';

// assets
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import MoreTwoToneIcon from '@mui/icons-material/MoreTwoTone';
import useAxios from '../../hooks/useAxios';

import FaceIcon from '@mui/icons-material/Face';
import { gridSpacing } from '../../store/constant';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import UserDownload from '../../components/csv/user.download';
import Select from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
import CategoryRow from './category.row';
import { CategoryListType } from '../../types/content.types';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Theme } from '@mui/system';
import PickListDetail from '../pick/PickListDetail';
import { useDispatch } from 'react-redux';
import { LOADING_END, LOADING_START } from '../../store/actions';
// ==============================|| USER LIST 1 ||============================== //

const getDropWrapper = (isDraggingOver: boolean, theme: Theme) => ({
    background: isDraggingOver ? theme.palette.secondary.light + 50 : 'transparent'
});
const CategoryList = () => {
    const theme = useTheme();
    const { axiosService } = useAxios();
    const navigation = useNavigate();
    const [data, setData] = React.useState<CategoryListType[]>([]);
    const [mainList, setMainList] = useState<string[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const getData = async () => {
        const response = await axiosService.post('/content/category/list');
        const { result, list } = response.data;
        if (result) {
            setData([...list]);
            setMainList([...list.map((v) => `category_${v.idx}`)]);
        }
    };

    React.useEffect(() => {
        getData();
    }, []);

    const onDragEnd = (result: DropResult) => {
        const { source, destination, draggableId, type } = result;
        console.log('result', result);

        if (!destination) return;

        if (destination.droppableId === source.droppableId && destination.index === source.index) return;

        if (!result.destination) {
            return;
        }

        const items = reorder(data, result.source.index, result.destination.index);

        // @ts-ignore
        setData([...items]);
        const orderData = items.map((v: any, index) => ({ order: index + 1, idx: v.idx as any }));
        console.log('ORDER', orderData);
        // setData([...orderData]);
        // const newUserStoryOrder = Array.from(mainList);
        // newUserStoryOrder.splice(source.index, 1); // remove dragged column
        // newUserStoryOrder.splice(destination?.index, 0, draggableId); // set column new position
        // setMainList([...newUserStoryOrder]);
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };
    const dispatch = useDispatch();
    const sortListSave = async () => {
        const orderData = data.map((v: any, index) => ({ order: index + 1, idx: v.idx as any }));
        try {
            dispatch({ type: LOADING_START });
            await axiosService.post('content/category/order/update', { data: orderData });
            await getData();
        } catch (e) {
        } finally {
            dispatch({ type: LOADING_END });
        }

        // console.log('SORT, ', sort);
        // const sortData = sort.map((idx: string, index: number) => pickData.filter((item) => `category_${item.idx}` === idx)[0]);
        // setData([...sortData]);
        // console.log('SORT DATA', sortData);
    };
    console.log('main', mainList);
    console.log('Data', data);
    return (
        <>
            <Grid container flexDirection={'row-reverse'}>
                <Grid item>
                    <Button onClick={() => navigation('/content/category/add/new')}> Add Category</Button>
                </Grid>
            </Grid>
            <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                <Button variant={'outlined'} onClick={() => sortListSave()}>
                    SAVE
                </Button>
            </Grid>
            <TableContainer>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            // <Table {...provided.droppableProps} ref={provided.innerRef} sx={getDropWrapper(snapshot.isDraggingOver, theme)}>
                            //     <TableHead>
                            //         <TableRow>
                            //             <TableCell sx={{ pl: 3 }}>#</TableCell>
                            //             <TableCell>name</TableCell>
                            //             <TableCell>image</TableCell>
                            //             <TableCell>rank</TableCell>
                            //             <TableCell>use</TableCell>
                            //             <TableCell align="center" sx={{ pr: 3 }}>
                            //                 More
                            //             </TableCell>
                            //         </TableRow>
                            //     </TableHead>
                            //     <TableBody>
                            //         {data.map((row, index: number) => (
                            //             // const story = data.filter((item) => `category_${item.idx}` === idx)[0];
                            //             // console.log('STORY,', story);
                            //             <CategoryRow row={row} index={index} />
                            //         ))}
                            //         {provided.placeholder}
                            //     </TableBody>
                            // </Table>
                            <Table {...provided.droppableProps} ref={provided.innerRef}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>name</TableCell>
                                        <TableCell>image</TableCell>
                                        <TableCell>rank</TableCell>
                                        <TableCell>use</TableCell>
                                        <TableCell align="center" sx={{ pr: 3 }}>
                                            More
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((item, index) => (
                                        <CategoryRow row={item} index={index} />
                                    ))}
                                </TableBody>
                                {provided.placeholder}
                            </Table>
                        )}
                    </Droppable>
                </DragDropContext>
            </TableContainer>
        </>
    );
};

export default CategoryList;
