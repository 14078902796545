import React, { useEffect, useState } from 'react';
import { pickListTypes } from '../../types/pick';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Avatar from '../../ui-component/extended/Avatar';
import FaceIcon from '@mui/icons-material/Face';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import dayFormat, { dayFormatTime, timeFind } from '../../utils/times/timeFormats';
import { BookStatusCase, TransactionBagCase } from '../../utils/common';
import PickPageListDetail from './PickPageListDetail';
import Zoom from 'react-medium-image-zoom';
import MainCard from '../../ui-component/cards/MainCard';
import useAxiosServices from '../../hooks/useAxios';
import { QrCodeOutlined } from '@mui/icons-material';

interface Props {
    row: pickListTypes;
    profileView: boolean;
}

export const depotReportSelectCase = (type) => {
    switch (type) {
        case 1:
            return 'Bag ripped - has containers';
        case 2:
            return 'Bag ripped - no containers';
        case 3:
            return 'Bag without RECAN ID';
        case 4:
            return 'Bag contains rubbish / hazardous material';
        case 5:
            return 'Contains ineligible containers';
        case 6:
            return 'Lids on containers';
    }
};

const PickListMore = ({ row, profileView }: Props) => {
    const theme = useTheme();
    const navigation = useNavigate();
    const [qrList, setQrList] = useState<{ code: string }[]>([]);
    const { axiosService } = useAxiosServices();
    const handlerGoToProfile = (idx: number) => {
        navigation(`/user/default/${idx}`);
    };
    console.log(row);
    const depotReport = row?.depotReport ? row.depotReport.split(`|:|`) : [];
    const getCode = async () => {
        const api = await axiosService.post('/pick/app/detail/codeList', { idx: row.idx });
        const { status, data } = api.data;
        if (status) {
            setQrList([...data]);
        }
    };

    useEffect(() => {
        getCode();
    }, []);

    const [drvierCheck, setDriverCheck] = useState(row.driverCheck === 1);
    const [depotCheck, setDepotCheck] = useState(row.depotCheck === 1);

    const checkReport = async (type) => {
        const api = await axiosService.post('/pick/reportCheck', { type, idx: row.idx });
        const { status } = api.data;
        if (status) {
            if (type === 1) {
                setDriverCheck(true);
            }
            if (type === 2) {
                setDepotCheck(true);
            }
        }
    };

    console.log(qrList);
    return (
        <>
            <MainCard title={'Pick info'} xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs margin={4}>
                        <Grid item xs zeroMinWidth>
                            <Grid container alignItems={'center'} marginY={2}>
                                <Grid item mr={2}>
                                    <Typography align="left" variant="subtitle2" component="div">
                                        Status
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography align="left" variant="subtitle1" noWrap>
                                        {BookStatusCase(row.status)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container alignItems={'center'} marginY={2}>
                                <Grid item mr={2}>
                                    <Typography align="left" variant="subtitle2" component="div">
                                        Email
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography align="left" variant="subtitle1" noWrap>
                                        {row.user.userEmail}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container alignItems={'center'} marginY={2}>
                                <Grid item mr={2}>
                                    <Typography align="left" variant="subtitle2" component="div">
                                        Address
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography align="left" variant="subtitle1" component="div">
                                        {row.address}
                                        {'\n'}
                                        {row.addressMore}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container alignItems={'center'} marginY={2}>
                                <Grid item mr={2}>
                                    <Typography align="left" variant="subtitle2" component="div">
                                        Note
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography align="left" variant="subtitle1" component="div">
                                        {row.pickPlace}{' '}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container alignItems={'center'} marginY={2}>
                                <Grid item mr={2}>
                                    <Typography align="left" variant="subtitle2" component="div">
                                        Date
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography align="left" variant="subtitle1" component="div">
                                        {dayFormat(row.pickDate)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container alignItems={'center'} marginY={2}>
                                <Grid item mr={2}>
                                    <Typography align="left" variant="subtitle2" component="div">
                                        Picked up
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography align="left" variant="subtitle1" component="div">
                                        {dayFormatTime(row.driverTime)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container alignItems={'center'} marginY={2}>
                                <Grid item mr={2}>
                                    <Typography align="left" variant="subtitle2" component="div">
                                        Completed by
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography align="left" variant="subtitle1" component="div">
                                        {dayFormatTime(row.depotTime)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {row.pickImage && (
                                <Grid container alignItems={'center'} marginY={2}>
                                    <Grid item mr={2}>
                                        <Typography align="left" variant="subtitle2" component="div">
                                            Image
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Zoom>
                                            <img
                                                src={row.pickImage}
                                                style={{ marginLeft: 10, width: 100, objectFit: 'contain', margin: '0 auto' }}
                                            />
                                        </Zoom>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs margin={4}>
                        <Grid item xs zeroMinWidth>
                            <Grid container alignItems={'center'} marginY={2}>
                                <Grid item mr={2}>
                                    <Typography align="left" variant="subtitle2" component="div">
                                        Driver
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography align="left" variant="subtitle1" noWrap>
                                        {row.driver?.userName ?? 'N/A'}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container alignItems={'center'} marginY={2}>
                                <Grid item mr={2} xs={12}>
                                    <Typography align="left" variant="subtitle2" component="div">
                                        Bag {`(${row.bag})`}
                                    </Typography>
                                </Grid>
                                {row.bags && row.bags.length > 0 && (
                                    <Grid item xs={12} my={1}>
                                        <TableContainer>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead sx={{ '& th,& td': { whiteSpace: 'nowrap' } }}>
                                                    <TableRow>
                                                        <TableCell>Type</TableCell>
                                                        <TableCell>Bag</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody sx={{ '& th,& td': { whiteSpace: 'nowrap' } }}>
                                                    {row.bags.map((v, i) => (
                                                        <TableRow hover>
                                                            <TableCell sx={{ minWidth: 40, width: 40, height: 46 }}>
                                                                {TransactionBagCase(v.bagType)}
                                                            </TableCell>
                                                            <TableCell sx={{ minWidth: 40, width: 40, height: 46 }}>{v.bagEa}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                )}
                            </Grid>
                            {/* <Grid container alignItems={'center'} marginY={2}> */}
                            {/*    <Grid item mr={2}> */}
                            {/*        <Typography align="left" variant="subtitle2" component="div"> */}
                            {/*            Need Recan Bag */}
                            {/*        </Typography> */}
                            {/*    </Grid> */}
                            {/*    <Grid item> */}
                            {/*        <Typography align="left" variant="subtitle1" component="div"> */}
                            {/*            {row.bagUse === 1 ? 'use' : 'unUse'} */}
                            {/*        </Typography> */}
                            {/*    </Grid> */}
                            {/* </Grid> */}
                            {/* <Grid container alignItems={'center'} marginY={2}> */}
                            {/*    <Grid item mr={2}> */}
                            {/*        <Typography align="left" variant="subtitle2" component="div"> */}
                            {/*            need Bag */}
                            {/*        </Typography> */}
                            {/*    </Grid> */}
                            {/*    <Grid item> */}
                            {/*        <Typography align="left" variant="subtitle1" component="div"> */}
                            {/*            {row.needBag} */}
                            {/*        </Typography> */}
                            {/*    </Grid> */}
                            {/* </Grid> */}
                            <Grid container alignItems={'center'} marginY={2}>
                                <Grid item mr={2}>
                                    <Typography align="left" variant="subtitle2" component="div">
                                        Pet
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography align="left" variant="subtitle1" component="div">
                                        {row.dogCheck === 1 ? 'Check' : 'UnCheck'}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container alignItems={'center'} marginY={2}>
                                <Grid item mr={2} xs={12}>
                                    <Typography align="left" variant="subtitle2" component="div">
                                        Qrcode
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid item xs={12} my={1}>
                                        <TableContainer>
                                            <Table size="small" aria-label="purchases">
                                                <TableBody sx={{ '& th,& td': { whiteSpace: 'nowrap' } }}>
                                                    {qrList.map((v, i) => (
                                                        <TableRow>
                                                            <TableCell sx={{ minWidth: 40, width: 40, height: 46 }}>
                                                                <QrCodeOutlined />
                                                            </TableCell>
                                                            <TableCell sx={{ minWidth: 40, width: 40, height: 46 }}>{v.code}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MainCard>
            <div style={{ margin: '30px' }} />
            {row.driverTime && (
                <MainCard title={'Driver Report'}>
                    {!drvierCheck && (
                        <Grid container justifyContent={'flex-start'} onClick={() => checkReport(1)}>
                            <Button>Check</Button>
                        </Grid>
                    )}

                    <Grid container spacing={2}>
                        <Grid item xs margin={4}>
                            <Grid item my={5}>
                                <Typography align="left" variant="h4" component="div">
                                    {'Driver FeedBack'}
                                </Typography>
                            </Grid>
                            <Grid item my={5}>
                                <Typography align="left" variant="h5" component="div">
                                    {row.report ?? '-'}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={8} margin={4}>
                            <Grid item my={5}>
                                <Typography align="left" variant="h4" component="div">
                                    {'Driver Image'}
                                </Typography>
                            </Grid>
                            <Grid item my={5}>
                                {row.reportImage && (
                                    <Zoom>
                                        <img src={row.reportImage} style={{ marginLeft: 10, width: 100, height: 100, margin: '0 auto' }} />
                                    </Zoom>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </MainCard>
            )}
            <div style={{ margin: '30px' }} />
            {row.status === 5 && (
                <MainCard title={'Depot Report'}>
                    {!depotCheck && (
                        <Grid container justifyContent={'flex-start'} onClick={() => checkReport(2)}>
                            <Button>Check</Button>
                        </Grid>
                    )}
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs margin={4}>
                            <Grid item xs zeroMinWidth>
                                <Grid container alignItems={'center'} marginY={2}>
                                    <Grid item mr={2}>
                                        <Typography align="left" variant="subtitle2" component="div">
                                            Glass
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="left" variant="subtitle1" noWrap>
                                            {row.glass}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems={'center'} marginY={2}>
                                    <Grid item mr={2}>
                                        <Typography align="left" variant="subtitle2" component="div">
                                            Clear Plastic
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="left" variant="subtitle1" noWrap>
                                            {row.clearPlastic}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems={'center'} marginY={2}>
                                    <Grid item mr={2}>
                                        <Typography align="left" variant="subtitle2" component="div">
                                            Aluminium
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="left" variant="subtitle1" component="div">
                                            {row.aluminium}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems={'center'} marginY={2}>
                                    <Grid item mr={2}>
                                        <Typography align="left" variant="subtitle2" component="div">
                                            Coloured Plastic
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="left" variant="subtitle1" component="div">
                                            {row.coloredPlastic}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems={'center'} marginY={2}>
                                    <Grid item mr={2}>
                                        <Typography align="left" variant="subtitle2" component="div">
                                            HDPE
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="left" variant="subtitle1" component="div">
                                            {row.hdpe}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems={'center'} marginY={2}>
                                    <Grid item mr={2}>
                                        <Typography align="left" variant="subtitle2" component="div">
                                            LPB
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="left" variant="subtitle1" noWrap>
                                            {row.lpb}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs margin={4}>
                            <Grid item xs zeroMinWidth>
                                <Grid container alignItems={'center'} marginY={2}>
                                    <Grid item mr={2}>
                                        <Typography align="left" variant="subtitle2" component="div">
                                            Steel
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="left" variant="subtitle1" noWrap>
                                            {row.steel}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems={'center'} marginY={2}>
                                    <Grid item mr={2}>
                                        <Typography align="left" variant="subtitle2" component="div">
                                            Other
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="left" variant="subtitle1" component="div">
                                            {row.other}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems={'center'} marginY={2}>
                                    <Grid item mr={2}>
                                        <Typography align="left" variant="subtitle2" component="div">
                                            Ineligible
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="left" variant="subtitle1" component="div">
                                            {row.ineligible}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems={'center'} marginY={2}>
                                    <Grid item mr={2}>
                                        <Typography align="left" variant="h5" component="div">
                                            Credit
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="left" variant="subtitle1" component="div" color={theme.palette.primary.dark}>
                                            {row.credit}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} ml={4}>
                        <Grid item my={2}>
                            <Typography align="left" variant="h4" component="div">
                                Issue Report
                            </Typography>
                        </Grid>
                        {depotReport.map((v) => (
                            <Grid item xs={12} my={1}>
                                <Typography align="left" variant="h5" component="div">
                                    {depotReportSelectCase(Number(v))}
                                </Typography>
                            </Grid>
                        ))}
                        <Grid item xs={12} my={1}>
                            <Typography align="left" variant="h5" component="div">
                                {row.depotText}
                            </Typography>
                        </Grid>
                    </Grid>
                </MainCard>
            )}
        </>
    );
};

export default PickListMore;
