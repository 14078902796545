import { IconMusic } from '@tabler/icons';

const icons = {
    IconMusic
};

const MusicRoute = {
    id: 'music-route',
    type: 'group',
    children: [
        {
            id: 'music',
            title: 'Content',
            type: 'collapse',
            icon: icons.IconMusic,
            children: [
                {
                    id: 'music-all',
                    title: 'All',
                    type: 'item',
                    url: '/content/music/list'
                },
                {
                    id: 'music-meditation',
                    title: 'Meditation',
                    type: 'item',
                    url: '/content/music/category/3'
                },
                {
                    id: 'music-Sleep',
                    title: 'Sleep',
                    type: 'item',
                    url: '/content/music/category/5'
                },
                {
                    id: 'music-Classic',
                    title: 'Classic',
                    type: 'item',
                    url: '/content/music/category/4'
                },
                {
                    id: 'music-Kids',
                    title: 'Kids',
                    type: 'item',
                    url: '/content/music/category/7'
                }
            ]
        }
    ]
};

export default MusicRoute;
