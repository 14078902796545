import axios, { AxiosError, AxiosResponse } from 'axios';
import { Cookies } from 'react-cookie';
import { Navigate, useNavigate, useRoutes } from 'react-router-dom';
import { useContext, useEffect, useMemo } from 'react';
import { LOGOUT } from '../store/actions';
import useAuth from './useAuth';
import AuthContext from '../contexts/JWTContext';

// const cookies = new Cookies();
// const baseUrl = process.env.REACT_APP_BASE_URL;

const useAxiosServices = (func: any = false) => {
    const cookies = new Cookies();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate();
    const context = useContext(AuthContext);

    const axiosApiRefreshToken = axios.create({
        baseURL: baseUrl,
        withCredentials: true
    });
    const axiosServicesConfig = axios.create({
        baseURL: baseUrl,
        withCredentials: true
    });
    useEffect(() => {}, []);

    axiosServicesConfig.interceptors.request.use(
        async (config) => {
            const accessToken = localStorage.getItem('accessToken');
            if (accessToken) {
                const requestHeader = config.headers;
                return {
                    ...config,
                    headers: {
                        ...requestHeader,
                        Authorization: `Bearer ${accessToken}`
                    }
                };
            }
            return config;
        },
        (error) => {
            console.log(error);
            Promise.reject(error);
        }
    );

    axiosApiRefreshToken.interceptors.request.use(
        async (config) => {
            const refreshToken = localStorage.getItem('refreshToken');
            if (refreshToken) {
                const requestHeader = config.headers;
                return {
                    ...config,
                    headers: {
                        ...requestHeader,
                        Authorization: `Bearer ${refreshToken}`
                    }
                };
            }
            return config;
        },
        (error) => {
            Promise.reject(error);
        }
    );

    axiosServicesConfig.interceptors.response.use(
        (response) => {
            const originalRequest = response.config;
            if (originalRequest.url === '/users/login/admin') {
                const { accessToken, refreshToken } = response.data?.data;
                if (accessToken && refreshToken) {
                    console.log('accessToken && refreshToken', accessToken, refreshToken);
                    localStorage.setItem('refreshToken', refreshToken);
                    localStorage.setItem('accessToken', accessToken);
                }
            }
            return response;
        },

        async (error: AxiosError) => {
            const originalRequest = error.config;
            const refreshToken = localStorage.getItem('refreshToken');
            if (error.response?.status === 401) {
                if (func) {
                    func();
                } else {
                    if (!context) throw new Error('context must be use inside provider');
                    context.logout();
                }
                alert('Check information!');
                return Promise.reject(error);
            }
            if (error.response?.status === 401 && originalRequest.url === '/users/refresh') {
                if (func) {
                    func();
                } else {
                    if (!context) throw new Error('context must be use inside provider');
                    context.logout();
                }
                alert('Check information.');
                return Promise.reject(error);
            }
            if (error.response?.status === 401 && refreshToken) {
                try {
                    if (refreshToken) {
                        const res: AxiosResponse<{ accessToken: string }> = await axiosApiRefreshToken.get('/users/refresh');
                        const { accessToken } = res.data;
                        localStorage.setItem('accessToken', accessToken);
                        return await axiosServicesConfig(originalRequest);
                    }
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('refreshToken');
                    navigate('/login', { replace: true });
                    alert('You need to log in.');
                    await Promise.reject(error);
                } catch (e) {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('refreshToken');
                    navigate('/login', { replace: true });
                    alert('You need to log in..');
                    await Promise.reject(error);
                }
            }
            alert(`${error}`);
            return Promise.reject(error);
        }
    );
    const axiosService = useMemo(() => axiosServicesConfig, []);
    const serviceToken = useMemo(() => {
        const token = localStorage.getItem('refreshToken');
        return token;
    }, []);
    return { axiosService, serviceToken };
};

export default useAxiosServices;
