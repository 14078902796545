import React, { CSSProperties } from 'react';
import { userListProfile } from '../../_mockApis/user-profile/types';
import { Box, Chip, Grid, IconButton, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import Avatar from '../../ui-component/extended/Avatar';
import FaceIcon from '@mui/icons-material/Face';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import MoreTwoToneIcon from '@mui/icons-material/MoreTwoTone';
import { Theme, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { CategoryListType, ContentListType } from '../../types/content.types';
import { Draggable } from 'react-beautiful-dnd';
import { RadioButtonUnchecked, Check } from '@mui/icons-material';

interface props {
    row: ContentListType;
    index: number;
}
const getDragWrapper = (isDragging: boolean, theme: Theme, open: boolean): CSSProperties | undefined => ({
    backgroundColor: isDragging || open ? 'rgba(17,25,54,0.62)' : 'transparent',
    width: '100%',
    userSelect: 'none'
});
const ContentCategoryRow = ({ row, index }: props) => {
    const theme = useTheme();
    const navigation = useNavigate();
    const handlerGoToProfile = (idx: number) => {
        navigation(`/content/music/add/${idx}`);
    };

    const TransId = (dat: userListProfile) => {
        console.log(dat.snsType);
        switch (dat.snsType) {
            case 1:
                return dat.userId;
            case 2:
                return 'Google';
            case 3:
                return 'Apple';
            case 4:
                return 'Paypal';
            default:
                return dat.userId;
        }
    };
    const [open, setOpen] = React.useState(false);
    console.log('`category_${row.idx}`', `category_${row.idx}`);
    return (
        <Draggable key={`${row.idx}`} draggableId={`${row.idx}`} index={index}>
            {(provideds, snapshots) => (
                <TableRow
                    hover
                    ref={provideds.innerRef}
                    {...provideds.draggableProps}
                    {...provideds.dragHandleProps}
                    sx={getDragWrapper(snapshots.isDragging, theme, open)}
                >
                    <TableCell>
                        <Grid container spacing={2} alignItems="center" style={{ backgroundColor: 'transparent' }}>
                            <Grid item xs zeroMinWidth style={{ backgroundColor: 'transparent' }}>
                                <Typography align="left" variant="subtitle1" component="div">
                                    {row.title}
                                </Typography>
                            </Grid>
                        </Grid>
                    </TableCell>
                    <TableCell>
                        <Typography align="left" variant="subtitle2" noWrap>
                            <Box
                                alignItems={'center'}
                                justifyContent={'center'}
                                border={1}
                                borderColor={'#222'}
                                borderRadius={'12px'}
                                width={`${50}px`}
                                height={`${50}px`}
                            >
                                <img src={row.image} style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt={row.image} />
                            </Box>
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography align="left" variant="subtitle2" noWrap>
                            {row.categoryName}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography align="left" variant="subtitle2" noWrap>
                            {index + 1}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography align="left" variant="subtitle2" noWrap>
                            {row.duration}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        {row.agree === 1 && (
                            <Chip
                                label="Active"
                                size="small"
                                sx={{
                                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                                    color: theme.palette.success.dark
                                }}
                            />
                        )}
                        {row.agree === 0 && (
                            <Chip
                                label="UnActive"
                                size="small"
                                sx={{
                                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light + 80,
                                    color: theme.palette.orange.dark
                                }}
                            />
                        )}
                    </TableCell>
                    <TableCell>
                        {row.recommend ? (
                            <Box flexDirection={'row'} alignItems={'center'} display={'flex'}>
                                <Check /> <Typography ml={'10px'}>{row.recommendRank}</Typography>
                            </Box>
                        ) : null}
                    </TableCell>
                    <TableCell align="center" sx={{ pr: 3 }}>
                        <Stack direction="row" justifyContent="center" alignItems="center">
                            {/* <Tooltip placement="top" title="Message"> */}
                            {/*    <IconButton color="primary" aria-label="delete" size="large"> */}
                            {/*        <ChatBubbleTwoToneIcon sx={{ fontSize: '1.1rem' }} /> */}
                            {/*    </IconButton> */}
                            {/* </Tooltip> */}
                            <Tooltip placement="top" title="More">
                                <IconButton
                                    onClick={() => handlerGoToProfile(row.idx)}
                                    color="primary"
                                    sx={{
                                        color: theme.palette.orange.dark,
                                        borderColor: theme.palette.orange.main,
                                        '&:hover ': { background: theme.palette.orange.light }
                                    }}
                                    size="large"
                                >
                                    <MoreTwoToneIcon sx={{ fontSize: '1.1rem' }} />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </TableCell>
                </TableRow>
            )}
        </Draggable>
    );
};

export default ContentCategoryRow;
