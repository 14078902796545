import React, { useState, CSSProperties } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material-ui
import { Theme, useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    ButtonBase,
    Chip,
    Collapse,
    Grid,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';

// third-party

// project imports
import { SNACKBAR_OPEN, DELETE_STORY, LOADING_START, LOADING_END } from 'store/actions';
import { jsPDF } from 'jspdf';
// assets
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';

import { useNavigate } from 'react-router-dom';
import { TransactionListTypes } from '../../types/charity';
import { ToastSetting, TransactionTypeCase } from '../../utils/common';
import { locationListType, qrListType } from '../../types/pick';
import { format } from 'date-fns';
import useAxiosServices from '../../hooks/useAxios';
import { toast } from 'react-toastify';

interface Props {
    value: qrListType;
    index: number;
    openAlert: (idx: number) => void;
}

const QrRow = ({ value, index, openAlert }: Props) => {
    const theme = useTheme();
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    const SIZE = '300x300';
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigation = useNavigate();

    const { axiosService } = useAxiosServices();

    const getCode = async (idx) => {
        try {
            dispatch({ type: LOADING_START });
            const api = await axiosService.post('/pick/qrCode/reAdd', { idx });
            const { status, data } = api.data;
            await handleDownload(data);
        } catch (e) {
            console.log(e);
        } finally {
            dispatch({ type: LOADING_END });
        }
    };

    const handleDownload = (list) => {
        renderImagesPDF(list);
        doc.save('Qrcode.pdf');
        toast.info('Success Created', { ...ToastSetting.default });
    };

    const getImageSrc = (tagId) => {
        const URL = `https://chart.googleapis.com/chart?chs=${SIZE}&cht=qr&chl=${tagId}&choe=UTF-8`;
        return URL;
    };

    const renderImagesPDF = (list: string[]) => {
        let x = 0;
        let y = 10;
        let j = 0;
        let k = 0;
        let items = 0;
        const qrSize = 70;
        const A4pageWidth = 210; // 210mm
        const A4pageHeight = 297; // 297mm
        const vPadding = 10;
        const textPadding = 10;
        for (const row of list) {
            if (items >= 12) {
                doc.addPage();
                x = 0;
                y = 10;
                j = 0;
                k = 0;
                items = 0;
            }
            const imageData = new Image(300, 300);
            imageData.src = getImageSrc(row);
            // doc.text("Scan me", x, y);
            doc.addImage(imageData, 'PNG', x, y, qrSize, qrSize);
            doc.setFontSize(16);
            doc.text(`${row}`, x + textPadding, y + qrSize - 3);

            items++;
            if (x >= A4pageWidth - qrSize) {
                x = 0;
                k = 0;
                y = ++j * qrSize + vPadding;
            } else {
                x = ++k * qrSize;
            }
        }
    };

    return (
        <>
            <TableRow hover>
                <TableCell sx={{ pl: 3, minWidth: 40, width: 40, height: 46 }}>{value.idx}</TableCell>
                <TableCell component="th" scope="row">
                    <Typography align="left" variant="subtitle2" noWrap>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs zeroMinWidth>
                                <Typography align="left" variant="subtitle1" component="div">
                                    {`${value.startRange} ~ ${value.endRange}`}{' '}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Typography>
                </TableCell>

                <TableCell>
                    <Typography align="left" variant="subtitle2" noWrap>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs zeroMinWidth>
                                <Typography align="left" variant="subtitle1" component="div">
                                    {`${value.allRange}`}{' '}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography align="left" variant="subtitle2" noWrap>
                        {format(new Date(value.createdAt), 'yyyy-MM-dd')}
                    </Typography>
                </TableCell>

                <TableCell sx={{ width: 100, minWidth: 60 }}>
                    <ButtonBase
                        className="more-button"
                        sx={{ borderRadius: '12px' }}
                        onClick={handleClick}
                        aria-controls="menu-comment"
                        aria-haspopup="true"
                    >
                        <IconButton component="span" size="small" disableRipple>
                            <MoreVertTwoToneIcon fontSize="inherit" />
                        </IconButton>
                    </ButtonBase>
                    <Menu
                        id="menu-comment"
                        // @ts-ignore
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        variant="selectedMenu"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                getCode(value.idx);
                            }}
                        >
                            Download
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                openAlert(value.idx);
                            }}
                        >
                            Delete
                        </MenuItem>
                    </Menu>
                </TableCell>
            </TableRow>
        </>
    );
};

export default QrRow;
