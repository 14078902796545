import { IconMusic, IconStack } from '@tabler/icons';

const icons = {
    IconMusic,
    IconStack
};

const StatRoute = {
    id: 'music-stat',
    type: 'group',
    children: [
        {
            id: 'Stat',
            title: 'Stat',
            type: 'item',
            icon: icons.IconStack,
            url: '/stat/content'
        }
    ]
};

export default StatRoute;
