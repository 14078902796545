import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ContentRow from '../../content/content.row';
import { ContentListType } from '../../../types/content.types';
import UserContentRow from './user.content.row';

interface IProps {
    data: ContentListType[];
    type: 'book' | 'recent';
}
const UserContentTable = ({ data, type }: IProps) => (
    <TableContainer>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Brain volume</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>{data && data.map((row, index) => <UserContentRow row={row} index={index} />)}</TableBody>
        </Table>
    </TableContainer>
);

export default UserContentTable;
