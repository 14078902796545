// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    NativeSelect,
    Tab,
    Tabs,
    TextField,
    Typography
} from '@mui/material';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';
import * as yup from 'yup';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import useAxiosServices from '../../hooks/useAxios';
import { toast } from 'react-toastify';
import { groupRecentList, recentPlayCount, ToastSetting } from '../../utils/common';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { ContentListType, IRecentListType, IRecentListTypeCount } from '../../types/content.types';
import UserContentTable from '../userProfile/user.content/user.content.table';
import UserRecentTable from '../userProfile/user.content/user.recent.table';

function TabPanel(props: { children: React.ReactElement; value: number; index: number }) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`list-tabpanel-${index}`} aria-labelledby={`user-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

// ==============================|| PROFILE 1 - CHANGE PASSWORD ||============================== //

interface Props {
    id: any;
}
function a11yProps(index: number) {
    return {
        id: `user-tab-${index}`,
        'aria-controls': `list-tabpanel-${index}`
    };
}
const dateItem = [
    { label: 'Week', value: '7' },
    { label: 'Month', value: '30' },
    { label: 'Quarter', value: '120' }
];
const ContentStatPage = () => {
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue) => {
        setValue(newValue);
    };
    const [book, setBook] = useState<ContentListType[]>([]);
    const [recent, setRecent] = useState<IRecentListTypeCount[]>([]);
    const [type, setType] = useState('7');
    const { axiosService } = useAxiosServices();
    useEffect(() => {
        getData();
    }, [type]);

    const getData = async () => {
        const api = await axiosService.post('content/admin/userData/v2', { type });
        const { status, bookMarkList, recentList } = api.data;
        if (status) {
            setBook([...bookMarkList]);
            const convertList = groupRecentList(recentList ?? []);
            setRecent([...convertList]);
        } else {
            alert('error');
        }
    };
    const handleDateSort = (event) => {
        event?.target.value && setType(event?.target.value);
    };
    console.log('type', type);
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <SubCard title="Content Usage Statistics">
                    <Grid container spacing={gridSpacing} sx={{ mb: 1.75 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: '30px' }}>
                            <FormControl sx={{ width: '150px' }}>
                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                    Date
                                </InputLabel>
                                <NativeSelect
                                    id="SortingByplay"
                                    name="SortingByplay"
                                    value={type}
                                    onChange={(e) => handleDateSort(e)}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    {[
                                        { label: 'Week', value: '7' },
                                        { label: 'Month', value: '30' },
                                        { label: 'Quarter', value: '120' }
                                    ].map(({ value: monthValue, label }, index: number) => (
                                        <option key={index} value={monthValue}>
                                            {label}
                                        </option>
                                    ))}
                                </NativeSelect>
                            </FormControl>
                        </Box>
                        <Grid item xs={12} md={12}>
                            <Tabs
                                value={value}
                                indicatorColor={'primary'}
                                onChange={handleChange}
                                sx={{
                                    mb: 3,
                                    minHeight: 'auto',
                                    '& button': {
                                        minWidth: 100
                                    },
                                    '& a': {
                                        minHeight: 'auto',
                                        minWidth: 10,
                                        py: 1.5,
                                        px: 1,
                                        mr: 2.25,
                                        color: 'grey.600'
                                    },
                                    '& a.Mui-selected': {
                                        color: 'primary.main'
                                    }
                                }}
                                aria-label="simple tabs example"
                                variant="scrollable"
                            >
                                <Tab component={Link} to="#" label="BookMark" {...a11yProps(0)} />
                                {/* <Tab component={Link} to="#" label="Play" {...a11yProps(1)} /> */}
                                {/* <Tab component={Link} to="#" label="Credit" {...a11yProps(2)} /> */}
                                <Tab component={Link} to="#" label="Recent" {...a11yProps(1)} />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <UserContentTable data={book} type={'book'} />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <UserRecentTable data={recent} type={'recent'} />
                            </TabPanel>
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
        </Grid>
    );
};

export default ContentStatPage;
