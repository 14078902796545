import React, { useState, CSSProperties } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material-ui
import { Theme, useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    ButtonBase,
    Chip,
    Collapse,
    Grid,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';

// third-party

// project imports
import { SNACKBAR_OPEN, DELETE_STORY } from 'store/actions';

// assets
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';

// types
import { pickListTypes } from 'types/pick';
import Avatar from '../../ui-component/extended/Avatar';
import FaceIcon from '@mui/icons-material/Face';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PickListMore from './pickListMore';
import dayFormat, { timeFind } from '../../utils/times/timeFormats';
import PickTypeCard from '../../ui-component/swith/pickTypeCard';
import { useNavigate } from 'react-router-dom';
import { addressFormat } from '../../utils/common';

interface Props {
    value: pickListTypes;
    index: number;
    deletePick?: any;
}

const PickPageListDetail = ({ value, index, deletePick }: Props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigation = useNavigate();
    return (
        <>
            <TableRow hover style={{ cursor: 'pointer' }} onClick={() => setOpen((p) => !p)}>
                <TableCell sx={{ pl: 3, minWidth: 40, width: 40, height: 46 }}>{`AC-0${value.idx}`}</TableCell>
                <TableCell component="th" scope="row">
                    <Typography align="left" variant="subtitle2" noWrap>
                        {value.address}
                        {addressFormat(value.addressZip, value.addressMore)}
                    </Typography>
                </TableCell>
                {/* <TableCell component="th" scope="row"> */}
                {/*    <Typography align="left" variant="subtitle2" noWrap> */}
                {/*        {value.addressMore} */}
                {/*    </Typography> */}
                {/*    <Typography align="left" variant="subtitle2" noWrap> */}
                {/*        {value.addressZip} */}
                {/*    </Typography> */}
                {/* </TableCell> */}
                <TableCell component="th" scope="row">
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs zeroMinWidth>
                            <Typography display={'flex'} mb={1} align="left" variant="subtitle1" component="div">
                                <Link
                                    onClick={() => navigation(`/user/default/${value.userIdx}`)}
                                    underline="hover"
                                    color="default"
                                    sx={{
                                        overflow: 'hidden',
                                        display: 'block',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        ':hover': { color: 'primary.main' },
                                        cursor: 'pointer'
                                    }}
                                >
                                    {value.user?.userFirstName} {value.user?.userName}
                                </Link>
                                {value.user?.userStatus === 1 && (
                                    <CheckCircleIcon sx={{ ml: 1, color: 'success.dark', width: 14, height: 14 }} />
                                )}
                            </Typography>
                            <Typography align="left" variant="subtitle2" noWrap>
                                {value.user?.userNickName}
                            </Typography>
                            <Typography align="left" variant="subtitle2" noWrap>
                                {value.user?.userEmail}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Typography align="left" variant="subtitle2" noWrap>
                        {value.bagUse === 1 ? 'Use' : 'UnUse'}
                    </Typography>
                    <Typography align="left" variant="subtitle2" noWrap>
                        {`${value.bag}ea`}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography align="left" variant="subtitle2" noWrap>
                        {dayFormat(value.pickDate)}
                    </Typography>
                </TableCell>
                <TableCell>
                    <PickTypeCard type={value.status} />
                </TableCell>
                <TableCell component="th" scope="row">
                    {value.status === 2 ? (
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs zeroMinWidth>
                                <Typography align="left" variant="subtitle1" component="div">
                                    {value.user?.userName}{' '}
                                    {value.user?.userStatus === 1 && (
                                        <CheckCircleIcon sx={{ color: 'success.dark', width: 14, height: 14 }} />
                                    )}
                                </Typography>
                                <Typography align="left" variant="subtitle2" noWrap>
                                    {value.user?.userNickName}
                                </Typography>
                                <Typography align="left" variant="subtitle2" noWrap>
                                    {value.user?.userEmail}
                                </Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <Typography align="left" variant="subtitle2" noWrap>
                            N/A
                        </Typography>
                    )}
                </TableCell>
                <TableCell sx={{ width: 30, minWidth: 60 }}>
                    {/* <ButtonBase */}
                    {/*    className="more-button" */}
                    {/*    sx={{ borderRadius: '12px' }} */}
                    {/*    onClick={handleClick} */}
                    {/*    aria-controls="menu-comment" */}
                    {/*    aria-haspopup="true" */}
                    {/* > */}
                    {/*    <IconButton component="span" size="small" disableRipple> */}
                    {/*        <MoreVertTwoToneIcon fontSize="inherit" /> */}
                    {/*    </IconButton> */}
                    {/* </ButtonBase> */}
                    {/* <Menu */}
                    {/*    id="menu-comment" */}
                    {/*    anchorEl={anchorEl} */}
                    {/*    keepMounted */}
                    {/*    open={Boolean(anchorEl)} */}
                    {/*    onClose={handleClose} */}
                    {/*    variant="selectedMenu" */}
                    {/*    anchorOrigin={{ */}
                    {/*        vertical: 'bottom', */}
                    {/*        horizontal: 'right' */}
                    {/*    }} */}
                    {/*    transformOrigin={{ */}
                    {/*        vertical: 'top', */}
                    {/*        horizontal: 'right' */}
                    {/*    }} */}
                    {/* > */}
                    {/*    <MenuItem */}
                    {/*        onClick={() => { */}
                    {/*            handleClose(); */}
                    {/*        }} */}
                    {/*    > */}
                    {/*        More */}
                    {/*    </MenuItem> */}
                    {/* </Menu> */}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box
                            sx={{
                                margin: 0
                            }}
                        >
                            <TableContainer>
                                {deletePick && (
                                    <Box
                                        pt={'20px'}
                                        px={'30px'}
                                        width={'100%'}
                                        display={'flex'}
                                        flexDirection={'row'}
                                        justifyContent={'flex-start'}
                                    >
                                        <Button onClick={() => deletePick(value.idx)}>
                                            <Typography variant={'h5'} color={'red'}>
                                                Delete
                                            </Typography>
                                        </Button>
                                    </Box>
                                )}

                                <Table size="small" aria-label="purchases">
                                    <TableBody>
                                        <Box margin="20px">
                                            <PickListMore row={value} profileView={false} />
                                        </Box>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default PickPageListDetail;
