import other from './other';
import { NavItemType } from 'types';
import dashboard from './dashboard';
import userMenu from './userList';
import pickMenu from './pick';
import routingRoute from './routing.route';
import charityRoute from './charity.route';
import settingRoute from './setting.route';
import transactionRoute from './transaction.route';
import orderRouting from './order.route';
import ContentRoute from './content.route';
import MusicRoute from './music.route';
import StatRoute from './stat.route';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [dashboard, userMenu, ContentRoute, MusicRoute, StatRoute, settingRoute]
};

export default menuItems;
