import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import {
    Box,
    Button,
    Checkbox,
    Grid,
    InputAdornment,
    Menu,
    MenuItem,
    OutlinedInput,
    Pagination,
    Stack,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { IconSearch } from '@tabler/icons';
import MainCard from '../../ui-component/cards/MainCard';
import { jsPDF } from 'jspdf';
import useAxiosServices from '../../hooks/useAxios';
import { useDispatch } from 'react-redux';
import '../../assets/scss/Calender.css';
import { LOADING_END, LOADING_START } from '../../store/actions';
import Calendar from 'react-calendar';
import FormControl from '../../ui-component/extended/Form/FormControl';
import * as yup from 'yup';
import { useFormik } from 'formik';
import MaskedInput from 'react-text-mask';
import AnimateButton from '../../ui-component/extended/AnimateButton';
import { format } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';

const validationSchema = yup.object({
    postCode: yup.string().required('postcode is required'),
    postCode2: yup.string(),
    title: yup.string().min(2, 'Name should be of minimum 2 characters length').required('Name is required'),
    basic: yup.string().required('basic minimum  is required'),
    business: yup.string().required('business minimum  is required'),
    charity: yup.string().required('charity minimum  is required'),
    school: yup.string().required('school minimum is required')
});

function swithWeek(i: number) {
    switch (i) {
        case 0:
            return 'Sun';
        case 1:
            return 'Mon';
        case 2:
            return 'Tue';
        case 3:
            return 'Wed';
        case 4:
            return 'Tur';
        case 5:
            return 'Fri';
        case 6:
            return 'Sat';
        default:
            return '';
    }
}

const SuburbDetail = () => {
    const formik = useFormik({
        initialValues: {
            postCode: '',
            title: '',
            postCode2: '',
            basic: '',
            business: '',
            charity: '',
            school: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            await postHandler();
            console.log('value', values);
        }
    });
    console.log(formik.errors);
    const theme = useTheme();
    const dispatch = useDispatch();
    const { axiosService } = useAxiosServices();
    const navigation = useNavigate();
    const [value, onChange] = useState(new Date());

    const { idx } = useParams();
    const [modify, setModify] = useState(false);

    console.log(idx);

    useEffect(() => {
        if (idx) {
            getDetail();
        }
    }, [idx]);

    const [week, setWeek] = useState([0, 0, 0, 0, 0, 0, 0]);

    const weekHandler = (i) => {
        const temp = [...week];
        const target = temp[i] === 0 ? 1 : 0;
        const convert = [...temp];
        convert[i] = target;
        setWeek([...convert]);
    };

    const weekDisabledHandler = (date) => {
        console.log(disabledDate.includes(date));
        if (week[date.getDay()] === 1) {
            return true;
        } else {
            const convert = format(date, 'yyyy-MM-dd');
            return disabledDate.includes(convert);
        }
    };

    const [disabledDate, setDisabledDate] = useState<string[]>([]);
    const [active, setActive] = useState(true);
    const dayDisabledHandler = (date) => {
        const convert = format(date, 'yyyy-MM-dd');
        const tempList = [...disabledDate];
        if (disabledDate.includes(convert)) {
            const temp = tempList.filter((v) => v !== convert);
            setDisabledDate([...temp]);
        } else {
            tempList.push(convert);
            setDisabledDate([...tempList]);
        }
    };

    const postHandler = async () => {
        const url = modify ? '/pick/location/update' : '/pick/location/add';
        const postData = modify
            ? {
                  basic: formik.values.basic,
                  school: formik.values.school,
                  business: formik.values.business,
                  charity: formik.values.charity,
                  title: formik.values.title,
                  postCode: formik.values.postCode,
                  postCode2: formik.values.postCode2,
                  weekDisabled: week,
                  dateDisabled: disabledDate,
                  active: active ? 1 : 0,
                  idx
              }
            : {
                  basic: formik.values.basic,
                  school: formik.values.school,
                  business: formik.values.business,
                  charity: formik.values.charity,
                  title: formik.values.title,
                  postCode: formik.values.postCode,
                  postCode2: formik.values.postCode2,
                  weekDisabled: week,
                  dateDisabled: disabledDate,
                  active: active ? 1 : 0
              };
        const api = await axiosService.post(url, postData);
        const { data, status } = api.data;
        if (status) {
            const info = modify ? 'Success Update Subrub' : 'Success Create Subrub';
            navigation(`/setting/suburb/${data}`);
            toast.info(info, { ...ToastSetting.default });
        } else {
            toast.info('Success Create Chairty', { ...ToastSetting.default });
        }
    };

    const getDetail = async () => {
        const api = await axiosService.post('/pick/location/detail', { idx });
        const { data, status } = api.data;
        if (status) {
            await formik.setFieldValue('title', data.title);
            await formik.setFieldValue('postCode', data.postCode);
            await formik.setFieldValue('postCode2', data.postCode2);
            await formik.setFieldValue('business', data.business);
            await formik.setFieldValue('charity', data.charity);
            await formik.setFieldValue('school', data.school);
            await formik.setFieldValue('basic', data.basic);
            setDisabledDate([...(data.dateDisabled ?? [])]);
            setWeek([...(data.weekDisabled ?? [])]);
            setActive(data.active === 1);

            setModify(true);
        } else {
            navigation('/setting/suburb');
        }
    };

    const deleteDetail = async () => {
        const api = await axiosService.post('/pick/location/delete', { idx });
        const { data, status } = api.data;
        if (status) {
            navigation('/setting/suburb');
            toast.info('Success Delete', { ...ToastSetting.default });
        } else {
            toast.info('Error', { ...ToastSetting.default });
        }
    };

    return (
        <MainCard
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3">Suburb</Typography>
                    </Grid>
                </Grid>
            }
            content={false}
        >
            <Grid item xs={12} justify-content={'flex-end'}>
                <Box ml={4} mt={4} display={'flex'} alignItems={'center'} flexDirection={'row'} justifyContent={'space-between'}>
                    <AnimateButton>
                        <Button variant="contained" onClick={() => formik.handleSubmit()}>
                            {modify ? 'Update' : 'Add'}
                        </Button>
                    </AnimateButton>
                    {modify && (
                        <div style={{ display: 'flex', padding: '16px' }}>
                            <Button variant="contained" onClick={() => deleteDetail()}>
                                {'Delete'}
                            </Button>
                        </div>
                    )}
                </Box>
            </Grid>
            <Grid spacing={gridSpacing} container m={2}>
                <Grid item xs={12} md={6}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={gridSpacing}>
                            <Grid container item xs={12}>
                                <Grid item xs={10}>
                                    <TextField
                                        id="postCode"
                                        fullWidth
                                        label="POST CODE"
                                        value={formik.values.postCode}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.postCode && Boolean(formik.errors.postCode)}
                                        helperText={formik.touched.postCode && formik.errors.postCode}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    id="title"
                                    fullWidth
                                    label="Zone name"
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.title && Boolean(formik.errors.title)}
                                    helperText={formik.touched.title && formik.errors.title}
                                />
                            </Grid>
                        </Grid>
                    </form>
                    <Grid xs={12} mt={2}>
                        <Typography variant={'h4'}>Active</Typography>
                        <Switch
                            checked={active}
                            onChange={(event, checked) => setActive(checked)}
                            inputProps={{ 'aria-label': 'checked' }}
                        />
                    </Grid>
                    <Grid container>
                        <Grid container xs={12}>
                            <Typography variant={'h4'} my={2}>
                                Minimum Setting
                            </Typography>
                            <Grid item container xs={12} spacing={gridSpacing}>
                                <Grid item xs={6}>
                                    <TextField
                                        type={'number'}
                                        id="basic"
                                        fullWidth
                                        label="Basic Minimum"
                                        value={formik.values.basic}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.basic && Boolean(formik.errors.basic)}
                                        helperText={formik.touched.basic && formik.errors.basic}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        type={'number'}
                                        id="business"
                                        fullWidth
                                        label="Business Minimum"
                                        value={formik.values.business}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.business && Boolean(formik.errors.business)}
                                        helperText={formik.touched.business && formik.errors.business}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        type={'number'}
                                        id="charity"
                                        fullWidth
                                        label="Charity Minimum"
                                        value={formik.values.charity}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.charity && Boolean(formik.errors.charity)}
                                        helperText={formik.touched.charity && formik.errors.charity}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        type={'number'}
                                        id="school"
                                        fullWidth
                                        label="School Minimum"
                                        value={formik.values.school}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.school && Boolean(formik.errors.school)}
                                        helperText={formik.touched.school && formik.errors.school}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant={'h3'} mb={2}>
                        Inactivated days
                    </Typography>
                    <Grid display={'flex'} xs zeroMinWidth flexDirection={'row'}>
                        {week.map((v, i) => (
                            <Grid xs={1} justifyContent={'center'} alignItems={'center'}>
                                <Typography variant={'h4'}>{swithWeek(i)}</Typography>
                                <Checkbox
                                    color="primary"
                                    title={'dd'}
                                    checked={week[i] === 1}
                                    onChange={() => weekHandler(i)}
                                    sx={{
                                        color: theme.palette.primary.main,
                                        '&.Mui-checked': { color: theme.palette.primary.main }
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <Calendar
                        calendarType={'US'}
                        tileClassName={({ date, view }) => (weekDisabledHandler(date) ? 'noView' : null)}
                        onChange={(v) => dayDisabledHandler(v)}
                        value={value}
                    />
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default SuburbDetail;
